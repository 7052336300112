import React, { useState } from "react";
import { BasicTextViewWithImg, BasicTextViewWithImgHobby, EditButton } from "../EditProfileUtills";

import Hobby from '../../Kalyanlite/assets/Images/Hobby.svg'
import Music from '../../Kalyanlite/assets/Images/music.svg'
import Flim from '../../Kalyanlite/assets/Images/flim.svg'
import Run from '../../Kalyanlite/assets/Images/run.svg'
import Translate from '../../Kalyanlite/assets/Images/translate.svg'
import HeadSet from '../../Kalyanlite/assets/Images/headset.svg'
import Chair from '../../Kalyanlite/assets/Images/chair.svg'
import Book from '../../Kalyanlite/assets/Images/book.svg'
import Plate from '../../Kalyanlite/assets/Images/plate.svg'
import Webchat from '../../Kalyanlite/assets/Images/webchat.svg'
import ActionRestrict from "../../Kalyanlite/Common/ActionRestrict";


function HobbyListComponent(props) {
  
  const [isShowEdit, setEditView] = useState(false);


  return (
        <>
        <ActionRestrict>
          <EditButton
            editCallBack={() => {
              setEditView(true)
              props?.callHobbiesAndInterest();
            }}
          />
        </ActionRestrict>
          <div className="md:mb-8 p-5 space-y-3 md:space-x-0">
            <BasicTextViewWithImgHobby
              image={Hobby}
              title={"Hobbies / Interests"}
              value={props?.data?.hobbiesAndInterest || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={HeadSet}
              title={"Music Genres You Listen to"}
              value={props?.data?.musicListen || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Music}
              title={"Favorite Music Directors"}
              value={props?.data?.favoriteMusicDirectors || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Book}
              title={"Type of Books You Read"}
              value={props?.data?.TypeofBooksYouRead || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Flim}
              title={"Type of Movies You Watch"}
              value={props?.data?.TypeOfMoviesYouWatch || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Chair}
              title={"Favorite Movie Directors"}
              value={props?.data?.FavoriteMovieDirectors || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Run}
              title={"Sports/ Fitness Activities/ Games"}
              value={props?.data?.SportsAndFitnessActivitiesAndGames || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Plate}
              title={"Favourite Cuisine"}
              value={props?.data?.FavouriteCuisine || "Not Specified"}
            />
            {props?.editMyProfileData && props?.editMyProfileData?.motherTongue[0]?.name == "Malayalam" &&
            <>
            <BasicTextViewWithImgHobby
              image={Translate}
              title={"languages you know"}
              value={props?.data?.languagesYouKnow || "Not Specified"}
            />
            <BasicTextViewWithImgHobby
              image={Webchat}
              title={"languages that I wish to know"}
              value={props?.data?.languagesThatIwishToKnow || "Not Specified"}
            />
            </>
          }
          </div>
        </>
  );
}

export default HobbyListComponent;
