import React, { useState } from "react";
import { SelectDropdownUtilHeading, SelectDropdownUtil } from "../CustomSelect";
import { useEffect } from "react";
import PlusIcon from "../../../Images/Path 3979.svg"
import educationCheckImage from '../../../Images/Group 890.svg'

const PreferenceTextView = (props) => {

  useEffect(() => {
    //console.log(props.value,"string");
   }, [props.value]);

  //  let values=props?.value
  //  const result = values.replace(/,/g, ', ' );

   const text = props?.value

   const addSpaceAfterComma = (text) => {
     if (!text) {
       return ''; 
     }
     const result = text.replace(/,/g, ', ');
     return result;
   };
 
   const result = addSpaceAfterComma(text);
 
  return (
    <div className=" grid grid-cols-9 mt-[2rem]  ">
      <div className="col-span-9 md:col-span-4 pl-0 md:pl-[1rem]">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
        </p> 
      </div>
      <div className="md:col-span-5 col-span-5 md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] pl-0">
        <p className=" md:text-[12px] lg:text-[14px] text-[#7A7A7A] font-semibold break-words">
          

           {/* {props.value.replace(/,/g, ', ' )} */}
           {result}

          

        </p>
      </div>
    </div>
  );
};

const MultiSelectView = (props) => {
console.log(props.value,"props.valueprops.value");
  
const isV3User = localStorage.getItem("isV3User")

  useEffect(() =>{

  } ,[props.placeholder])
  return (
    <div className={ props?.page == "additionInfo" ? "grid grid-cols-9 mt-[2rem]" : (props?.fieldType >= 67 && props?.fieldType <= 84)  ? 'md:mt-[1rem] lg:mt-[1rem] xl:mt-[1.3rem]  mt-4' : ` md:grid md:grid-cols-9 ${isV3User? "pl-[.3rem] md:pl-[1.4rem] lg:pl-[1.4rem] mt-4 md:mt-[1rem]" : "mt-4 md:mt-[2rem]"} justify-center items-center  space-y-2 md:space-y-0`}>
       
      <div
       className={props?.page == "additionInfo" ? "md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] " : (props?.fieldType >= 67 && props?.fieldType <= 84)   ? 'flex items-center  md:pl-[1rem] lg:pl-[1rem]'  :`${ props?.page ==="search"? "col-span-2 flex items-center pr-[0.1rem] "
      : props.page=='physical' ? `md:col-span-3 lg:col-span-4 col-span-9 ${isV3User? "lg:pl-[1.4rem]" : "lg:pl-[1rem]" }  ` :`md:col-span-3 lg:col-span-4 col-span-9 ` }` } >
        <p className={(props?.fieldType >= 67 && props?.fieldType <= 84) && isV3User ?  "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1"  :isV3User?"  md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold   ": " md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "}>
          {props.title}
          {/* <!-- <span className="text-[#D10A11]">*</span> --> */}
        </p>
      </div>
      <div 
      className={ props?.page == "additionInfo"? "col-span-9 md:col-span-5 md:pr-[1rem] lg:pr-[4rem]   md:pl-[1rem] mt-2 md:mt-0"  : (props?.fieldType >= 67 && props?.fieldType <= 84)  ? 'md:pr-[1rem] lg:pr-[1rem] md:pl-[1rem] mt-2 md:mt-0' : `${ props?.page ==="search"? "col-span-7 "
      : props.page=='physical' ? `md:col-span-6 lg:col-span-5 col-span-9  ${isV3User? "md:pl-[1.2rem] lg:pl-[2rem]" : "md:pl-[1.2rem] lg:pl-[2rem]"} `:`md:col-span-6 lg:col-span-5 col-span-9 md:pr-[1rem]   md:pl-[1rem]` }` }

      // className="md:col-span-4 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]"
      >
        {/* <p className="w-full"> */}
          <div className="flex ">
            <label className={`${ (props?.fieldType >= 67 && props?.fieldType <= 84) ? 'select md:text-[12px] lg:text-[14px] xl:text-[15px] text-[#575556] w-full' :  'select font-bold text-[#575556] w-full '}`}>
              {/* <Select
            
            /> */}
              <SelectDropdownUtil
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                placeholder={props.placeholder != null ? props.placeholder: (props?.fieldType >= 67 && props?.fieldType <= 84)   ? 'Select' :`Any ${props.title}` }
                onChange={props.onChange}
                option={props?.options}
                isDisabled={props?.isDisabled}
                // mode={"multiple"}
                fieldType={props.fieldType}
              />
              {/* <select
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              onChange={props.onChange}
              onSelect={props.onChange}
              className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
            
            >{props?.options?.map((e, i) => (
                <option key={e.id} value={props.inputID =="parentsIsdCode"? e.isdcode:e.id} >{props.inputID =="parentsIsdCode"?e.isdcode+" - "+e.country:e.name}</option>
              ))}
            </select> */}
            </label>
          </div>
        {/* </p> */}
      </div>
    </div>
  );
};

const MultiSelectViewHeading = (props) => {

  const isV3User = localStorage.getItem("isV3User")
  useEffect(() =>{

  } ,[props.placeholder])
  return (
    <div className={` md:grid md:grid-cols-9 justify-center items-center mt-4 md:mt-[2rem] ${isV3User?"pl-[1rem]":""} space-y-2 md:space-y-0`}>
       
      <div
       className={`${ props?.page ==="search"? "col-span-2 flex items-center pr-[0.1rem] "
      : props.page=='physical' ? "md:col-span-3 lg:col-span-4 col-span-9 lg:pl-[1rem] " :"md:col-span-3 lg:col-span-4 col-span-9 " }` } >
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  ">
          {props.title}
          {/* <!-- <span className="text-[#D10A11]">*</span> --> */}
        </p>
      </div>
      <div 
      className={`${ props?.page ==="search"? "col-span-7 "
      : props.page=='physical' ? "md:col-span-6 lg:col-span-5 col-span-9 lg:pr-[4rem]  md:pl-[1.2rem] lg:pl-[2rem]":"md:col-span-6 lg:col-span-5 col-span-9 md:pr-[1rem]   md:pl-[1rem]" }` }

      // className="md:col-span-4 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]"
      >
        {/* <p className="w-full"> */}
          <div className="flex ">
            <label className=" select font-bold text-[#575556] w-full ">
              {/* <Select
            
            /> */}
              <SelectDropdownUtilHeading
                name={props.inputName}
                value={props?.title == "Raasi"?props?.options?.length==1?props.options[0].name:props.value:props.value}
                id={props.inputID}
                placeholder={props.placeholder != null ? props.placeholder:`Any ${props.title}` }
                onChange={props.onChange}
                option={props?.options}
                // mode={"multiple"}
              />
              {/* <select
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              onChange={props.onChange}
              onSelect={props.onChange}
              className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
            
            >{props?.options?.map((e, i) => (
                <option key={e.id} value={props.inputID =="parentsIsdCode"? e.isdcode:e.id} >{props.inputID =="parentsIsdCode"?e.isdcode+" - "+e.country:e.name}</option>
              ))}
            </select> */}
            </label>
          </div>
        {/* </p> */}
      </div>
    </div>
  );
};

const EducationCheckView = (props) => {
  console.log(props.value,"props.valueprops.value");
  const [isShowList, SetIsShowList] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState("");

  useEffect (() => {
    setSelectedHeading(props.value.filter((obj) => obj.selected === "Y").length)
  },[])
  return (
    <>
      <div className="flex cursor-pointer space-x-1 lg:space-x-3 items-center ">
        {isShowList?
        <img
          className=" w-[1rem] "
          src={educationCheckImage}
          alt=""
          onClick={() => SetIsShowList(!isShowList)}
        />
        :
        <img
          className=" w-[1rem] "
          src={PlusIcon}
          alt=""
          onClick={() => SetIsShowList(!isShowList)}
        />
}
        <label> 
          {props.title.selected === "P" ?
          <div className="partialCheck">
          <input
          type="checkbox"
          className={`accent-red-500 ${props?.isFrom =='KM-lite' ? 'lite':'border border-[#F18181]'} `}
          name={props.title.id}
          value={props.title.id}
          checked={true}
          onChange={(e) => {
            console.log(props?.data?.values?.education,"props?.data?.values?.education");
            if(props?.fromPath == "basic_advance_search"){
              props?.setFilterOnchange(true)
              props?.setIsPPEdited("yes")
            }else if(props?.isFrom=='KM-lite'){
              props?.filterOnchange()
              props?.setIsPPEdited("yes")
            }
            props.callBackSelectAll(e.target.value)
            // if(props?.data?.values?.education?.length == 1){
            //   props?.setEducationError(true)
            // }
            // else{
            //   props?.setEducationError(false)
            // }
          }}
        />
        </div>
        :
         props.value.filter((obj) => obj.selected === "Y").length>0 && props.value.filter((obj) => obj.selected === "Y").length!=props?.value?.length?
         <div className="partialCheck">
          <input
          type="checkbox"
          className={`accent-red-500 ${props?.isFrom =='KM-lite' ? 'lite':'border border-[#F18181]'} `}
          name={props.title.id}
          value={props.title.id}
          checked={true}
          onChange={(e) => {
            console.log(props?.data?.values?.education,"props?.data?.values?.education");
            if(props?.fromPath == "basic_advance_search"){
              props?.setFilterOnchange(true)
              props?.setIsPPEdited("yes")
            }            
            props.callBackSelectAll(e.target.value)
            // if(props?.data?.values?.education?.length == 1){
            //   props?.setEducationError(true)
            // }
            // else{
            //   props?.setEducationError(false)
            // }          
          }}
        />
        </div>
        
          :
          <input
            type="checkbox"
            className={`accent-red-500 ${props?.isFrom =='KM-lite' ? 'lite':'border border-[#F18181]'} `}
            checked={props.title.selected==="Y"}
            name={props.title.id}
            value={props.title.id}
            onChange={(e) => {
              console.log(props?.data?.values?.education,"props?.data?.values?.education");
              if(props?.fromPath == "basic_advance_search"){
                props?.setFilterOnchange(true)
                props?.setIsPPEdited("yes")
              }
              props.callBackSelectAll(e.target.value)
              // if(props?.data?.values?.education?.length == 1){
              //   props?.setEducationError(true)
              // }
              // else{
              //   props?.setEducationError(false)
              // }
            }}
          />
       } 
        </label> 
        <p className=" text-[#8F8F8F] text-[13px] md:text-[11px] lg:text-[14px] pl-2 font-semibold">
          {props.title.name}
        </p>
      </div>
      {isShowList ? (
        <>
          {props.value.map((e, i) => (
            <div key={i} className="flex space-x-1 lg:space-x-3 ml-8 items-center">
              <label>
                <input
                  type="checkbox"
                  className={`accent-red-500 ${props?.isFrom =='KM-lite' ? 'lite':'border border-[#F18181]'} `}
                  checked={e.selected==="Y"}
                  name={e.id}
                  value={e.id}
                  onChange={(e) => {
                    console.log(props?.data?.values?.education,"props?.data?.values?.education");
                    if(props?.fromPath == "basic_advance_search"){
                      props?.setFilterOnchange(true)
                      props?.setIsPPEdited("yes")
                    }
                    props.callBackSingleSelect(e.target.value)
                    // if(props?.data?.values?.education?.length == 1){
                    //   props?.setEducationError(true)
                    // }
                    // else{
                    //   props?.setEducationError(false)
                    // }                  
                  }}
                />
              </label>
              <p className=" text-[#8F8F8F] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                {e.name}
              </p>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
};


export const SelectExploreOption = ({ title, value, onChange }) => {
  const [isShowList, SetIsShowList] = useState(false);

  return (
    <>
      <div className="flex cursor-pointer space-x-1 lg:space-x-3 items-center ">
        {isShowList ?
          <img
            className=" w-[1rem] "
            src={educationCheckImage}
            alt=""
            onClick={() => SetIsShowList(!isShowList)}
          />
          :
          <img
            className=" w-[1rem] "
            src={PlusIcon}
            alt=""
            onClick={() => SetIsShowList(!isShowList)}
          />
        }
        <label>
          {title.selected === "P" ?
            <div className="partialCheck">
              <input
                type="checkbox"
                className={`accent-red-500 lite `}
                name={title.id}
                value={title.id}
                checked={true}
                onChange={(e) => {
                  onChange(e.target.value, true)
                }}
              />
            </div>
            :
            value.filter((obj) => obj.selected === "Y").length > 0 && value.filter((obj) => obj.selected === "Y").length != value?.length ?
              <div className="partialCheck">
                <input
                  type="checkbox"
                  className={`accent-red-500 lite`}
                  name={title.id}
                  value={title.id}
                  checked={true}
                  onChange={(e) => {
                    onChange(e.target.value, true)
                  }}
                />
              </div>

              :
              <input
                type="checkbox"
                className={`accent-red-500 lite`}
                checked={title.selected === "Y"}
                name={title.id}
                value={title.id}
                onChange={(e) => {
                  onChange(e.target.value, true)
                }}
              />
          }
        </label>
        <p className=" text-[#8F8F8F] text-[13px] md:text-[11px] lg:text-[14px] pl-2 font-semibold">
          {title.name}
        </p>
      </div>
      {isShowList ? (
        <>
          {value.map((e, i) => (
            <div key={i} className="flex space-x-1 lg:space-x-3 ml-8 items-center">
              <label>
                <input
                  type="checkbox"
                  className={`accent-red-500 lite`}
                  checked={e.selected === "Y"}
                  name={e.id}
                  value={e.id}
                  onChange={(e) => {
                    onChange(e.target.value, false)
                  }}
                />
              </label>
              <p className=" text-[#8F8F8F] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                {e.name}
              </p>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const BasicCheckBox = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <label>
      <input
        type="checkbox"
        checked={props.checked}
        // onChange={()=>{props.}}
        className="accent-red-500 border border-[#F18181] "
        // checked={}
        //   onChange={()=>}
      />
    </label>
  );
};

export {
  BasicCheckBox,
  EducationCheckView,
  PreferenceTextView,
  MultiSelectView,
  MultiSelectViewHeading
};
