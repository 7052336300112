import React, { useContext, useEffect, useMemo, useState } from "react";
import { SelectDropdownUtil, SingleSelectDropdown } from "./CustomSelect";
import DatePicker from "react-datepicker";
import "../../Components/ReasonforDeletion/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Constants, { EventKeys, EventName } from "../../Constants/constants";
import { IoMdArrowDropdown } from "react-icons/io"
import LoaderGif from "../../Gif/loader.gif"
import EditIcon from "../../Images/EditIcon.svg"
import EllipseImage from '../../Images/Ellipse 76.svg'
import profileGrayImage from '../../Images/Group 537-grey.svg'
import RightArrow from '../../Images/right-arrow (1).svg'
import CloseIcon from '../Kalyanlite/assets/Images/Group 166.svg'
import { useAnalytics } from "../../Hooks/usePageChange";
import CancelIcon from '../../Images/cancelicon.svg'
import { commaWithSpace } from "../../Utils";
import { NavBarContext } from "../../Contexts/NavBarContext";
const ProfileMenuItem = (props) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <div
      className="flex cursor-pointer"
      onClick={() => props.onClickCallBack()}
    >
      <div className={`${isV3User ? "flex items-center  w-[2.5rem] h-[2.5rem] rounded-full" : "flex items-center"} bg-[${props?.backgroundColor}] `}>
        <img className={`${isV3User ? "w-[50%] block ml-auto mr-auto " : "w-[1rem]"}`} src={props.image} alt="" />
      </div>
      {isV3User ?
        <p
          className={`pl-[1rem] flex items-center ${props.isSelected ? "text-[#D71920]" : "text-[#575556]"
            } font-semibold md:text-[11px] lg:text-[15px] opacity-[0.9]`}
        >
          {props.title}
        </p>
        :
        <p
          className={`pl-2 flex items-center ${props.isSelected ? "text-[#D10A11]" : "text-[#575556]"
            } font-semibold md:text-[13px] lg:text-[16px] opacity-[0.9]`}
        >
          {props.title}
        </p>
      }
    </div>
  );
};

const BasicTextView = (props) => {
  return (
    <div className=" grid grid-cols-9 mt-4  md:mt-[2rem]    ">
      <div className="col-span-9 md:col-span-4  md:pl-[1rem] lg:pl-[2rem]">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
        </p>
      </div>
      <div className="col-span-9 md:col-span-5 md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] ">
        <p className=" md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] text-[#575556] font-semibold">
          {props.value}
        </p>
      </div>
    </div>
  );
};
const BasicTextViewWithImg = (props) => {
  return (
    <div className="flex  md:p-2 relative">
      <img src={EllipseImage} className="w-9 md:w-12" alt="" />
      <span className="flex justify-center lg:pl-[0.65rem] absolute mt-[10px] md:pl-[0.65rem] pl-1 md:mt-[14px] lg:mt-[12px] top-0 md:top-[6%] lg:top-[12%]">
        <img className="w-6 h-6 md:w-7 md:h-7" src={props.image} alt="" />
      </span>
      <div className=" pl-2 mt-2 md:mt-0 lg:mt-0 leading-[17px] md:leading-[20px] lg:leading-[26px] ">
        <p className="text-[#575556] font-normal text-[12px] md:text-[13px] lg:text-[14px]  ">
          {props.title}
        </p>
        <span className="text-[#575556] text-[14px] font-bold whitespace-nowrap">
          {props.value}
        </span>
      </div>
    </div>
  );
};
const BasicEditTextView = (props) => {
  console.log((props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44, 'props.fieldType')
  const FieldType = (props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 || (props.fieldType >= 67 && props.fieldType <= 84)
  useEffect(() => {
    //console.log(props?.value,"othercaste");
  }, [props?.value])
  return (
    <div className={`  mt-4 ${props?.menu == "addfamily" || FieldType ? "" : "grid grid-cols-9"}  ${props?.isFrom == "additionalFields" ? "md:mt-[1rem] lg:mt-[1rem] xl:mt-[1.3rem]" : "md:mt-[2rem]"}   items-center md:p-0`}>
      <div className={`col-span-9 md:col-span-4  ${props?.menu == "addfamily" || FieldType ? "md:pl-[1rem] lg:pl-[1rem]" : "md:pl-[1rem] lg:pl-[2rem]"} `}>
        <p className={` ${props?.menu == "addfamily" ? "text-[#000000] md:text-[14px] font-bold lg:text-[13px] py-1 " : (props.fieldType >= 67 && props.fieldType <= 84) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : "text-[#7A7A7A] md:text-[12px] lg:text-[14px] flex  font-semibold"}    `}>
          {props.title}
          {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={`col-span-9 md:col-span-5 ${props?.menu == "addfamily" ? "md:pr-[1rem] lg:pr-[1rem]  md:pl-[1rem] mt-2 md:mt-0" : (props.fieldType >= 27 && props.fieldType <= 30) || props.fieldType == 44 ? "md:pr-[1rem] lg:pr-[2rem] pt-2  md:pl-[1rem] mt-2 md:mt-0" : (props.fieldType >= 67 && props.fieldType <= 84) ? "md:pr-[1rem] lg:pr-[1rem]  md:pl-[1rem] " : "md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem] mt-2 md:mt-0"}  `}>
        <p className={props?.inputID === "parentNumber" ? "RegPhone" : ""}>
          <input
            type={props.inputType}
            id={props.inputID}
            name={props.inputName}
            value={props?.value}
            className={props?.menu == "addfamily" ? "border border-[#8F8F8F] text-[12px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold " + (props.className ?? '') :
              (props.fieldType >= 67 && props.fieldType <= 84) ? "AdditionalFamily border border-[#8F8F8F] md:text-[12px] lg:text-[14px] xl:text-[15px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-Poppins-Regular " + (props.className ?? '') : " border border-[#8F8F8F] text-[14px] xl:text-[14px] text-[#575556] rounded-[3px] h-[35px]  block w-full p-[0.4rem] lg:p-2.5  font-bold " + (props.className ?? '')}
            placeholder={props.placeholder}
            autoComplete="off"
            onChange={props.onChange}
            disabled={props?.disabled}
            onKeyDown={props?.onKeyDown}
            defaultValue={props?.defaultValue}
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
          {props?.inputID === "parentNumber" && (
            props?.phoneNumberError != "" && (
              <p className="text-[red] text-[12px]">{props.phoneNumberError}</p>
            )
          )}
        </p>
      </div>
    </div>
  );
};

export const BasicDatePicker = ({ value, onChange, disabled, title, required, maxDate, name, error, formatedValue, minDate, menu }) => {
  return (
    <div className={`  ${menu == "horodob" ? "mt-1" : "grid grid-cols-9 mt-4 md:mt-[2rem]"}    items-center md:p-0`}>
      <div className={`col-span-9 ${menu == "horodob" ? "pl-[1rem] lg:pl-[1rem]" : "md:pl-[1rem] lg:pl-[2rem]"} md:col-span-4 `}>
        <p className={` ${menu == "horodob" ? "text-[13px] font-bold lg:text-[13px] text-[#000000]" : "md:text-[12px] lg:text-[14px] text-[#7A7A7A]"} flex  font-semibold `}>
          {title}
          {required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={`col-span-9 md:col-span-5 ${menu == "horodob" ? " pl-[1rem] mt-1" : "md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem] mt-2"} md:mt-0`}>
        {disabled
          ? <p className={` ${menu == "horodob" ? "   text-[#000000] md:text-[12px] lg:text-[12px]" : "md:text-[#7A7A7A] text-[#575556] md:text-[12px] lg:text-[14px]"}  font-semibold`}>
            {formatedValue}
          </p>
          : <p className={` ${menu == "horodob" ? "md:text-[12px] lg:text-[12px]" : ""}`}>
            <DatePicker
              wrapperClassName="basic-date-picker"
              selected={value}
              onChange={onChange}
              dateFormat="dd-MM-yyyy"
              minDate={minDate ?? new Date()}
              maxDate={maxDate ?? new Date()}
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
              dropdownMode="select"
              name={name}
            />
            {error && (
              <p className="text-[red] text-[12px]">{error}</p>
            )}
          </p>}
      </div>
    </div>
  );
};

const BasiSelectView = (props) => {
  return (
    <div className=" grid grid-cols-9 mt-4  md:mt-[2rem]   ">
      <div className="col-span-9  md:col-span-4 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
          {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className="col-span-9  md:col-span-5 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
        {
          props?.disabled
            ? <p className=" md:text-[12px] lg:text-[14px] md:text-[#7A7A7A] text-[#575556] font-semibold">
              {props.value}
            </p>
            : <p className={`${props?.title == "Employment Type" ?? "HideSelect"}`}>
              <div className="flex ">
                <label className=" select font-bold text-[#7A7A7A] w-full ">
                  <select
                    name={props.inputName}
                    value={props.value}
                    id={props.inputID}
                    onChange={props.onChange}
                    onSelect={props.onChange}
                    disabled={props?.disabled}
                    className="w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[8px]   "
                  >
                    {props?.options?.length > 1 &&
                      <option disabled>Select</option>}
                    {props?.options?.map((e, i) => (
                      <option
                        key={e.id}
                        selected={props.value === e.id && true}
                        value={props.inputID == "parentsIsdCode" ? e.isdcode : e.id}
                      >
                        {props.inputID == "parentsIsdCode"
                          ? e.isdcode + " - " + e.country
                          : e.name}
                      </option>
                    ))}
                  </select>
                  {props.error && (
                    <p className="text-[red] text-[12px]">{props.error}</p>
                  )}
                </label>
              </div>
            </p>
        }
      </div>
    </div>
  );
};

export const SelectInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
  fieldType,
  disabled
}) => {
  const OthersToEnd = (castes) => {
    if (!castes || !Array.isArray(castes)) {
      return [];
    }
    const othersIndex = castes.findIndex((caste) => caste.name === "Others");
    const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
    if (othersOption) {
      castes.push(othersOption);
    }

    return castes;
  }

  const customStyles = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#F7F8FA" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),

  };
  return (
    <div className={` ${menu == "packastro" || (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) ? " mt-4" : fieldType == 19 ? '' : "grid grid-cols-9 md:mt-[2rem]  mt-4"}    `}>
      <div className={`col-span-9  md:col-span-4 flex ${menu == "packastro" || (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) ? "md:pl-[1rem] lg:pl-[1rem]" : fieldType == 19 ? '' : "md:pl-[1rem] lg:pl-[2rem]"}  items-center   `}>
        <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" : (fieldType >= 67 && fieldType <= 84) ? "text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1" : "text-[#7A7A7A] md:text-[12px] lg:text-[14px]  font-semibold "}  `}>
          {title}
          {title == "Raasi" || title == "Star" || title == "Chevvai Dosham/Manglik" ?
            "" : menu == "packastro" ?
              required && <span className="text-[#D10A11] ml-1"> *</span> : required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={`${(fieldType >= 27 && fieldType <= 30) || fieldType == 44 || fieldType == 19 || (fieldType >= 67 && fieldType <= 84) ? '' : 'col-span-9 md:col-span-5 md:pl-[1rem]'} ${menu === "packastro" ? "md:pr-[1rem] lg:pr-[1rem]" : (fieldType >= 27 && fieldType <= 30) || fieldType == 44 || (fieldType >= 67 && fieldType <= 84) ? 'md:pr-[1rem] lg:pr-[1rem] md:pl-[1rem]' : fieldType == 19 ? '' : "md:pr-[1rem] lg:pr-[4rem] md:pl-[1rem]"}`}>
        <p className="  ">
          <div className="flex ">
            <label className={` ${menu == "packastro" ? "md:text-[12px]  lg:text-[12px] font-bold" : (fieldType >= 67 && fieldType <= 84) ? "AdditionalFamily md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556]" : "md:text-[12px]  lg:text-[14px] font-bold"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={title == "Raasi" ? options ? options?.length == 2 ? options[1].name : "Select" : "Select" : "Select"}
                name={name}
                value={value === undefined ? "null" : value}
                onChange={onChange}
                options={OthersToEnd(options)}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                styles={customStyles}
                // menuIsOpen={true}
                isDisabled={disabled}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                }}
                // styles={{
                //   control:(base)=>{
                //     return {
                //       ...base,
                //       border:"1px solid #8F8F8F",
                //       outline:"none",
                //       color:"#575556"
                //     }
                //   },
                //   option:(base,optionState)=>{
                //     return {
                //       ...base,
                //       color:optionState.isSelected ?"white":"#575556"
                //     }
                //   },
                //   singleValue:(base)=>{
                //     return {
                //       ...base,
                //       color:"#575556"
                //     }
                //   },
                // }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className={`text-[red] text-[12px] ${fieldType == 19 ? 'pt-2' : ''}`}>{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};


export const SelectCurrencyInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  menu,
  isDisabled
}) => {
  return (



    <div>
      <p>
        <div className="flex ">
          <label className={`font-bold ${menu == "addfamily" ? "md:text-[12px] lg:text-[12px] w-[5rem]" : " md:text-[12px] lg:text-[14px] w-[6rem]"} ${isDisabled ? "cursor-not-allowed" : ""} `}>
            <Select
              isSearchable={true}
              placeholder={title == "currency" ? defaultValue : "Select"}
              name={name}
              value={value === undefined ? "null" : value}
              onChange={onChange}
              options={options}
              blurInputOnSelect
              onBlur={onBlur}
              defaultValue={defaultValue}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
              }}
              styles={{
                control: (base) => {
                  return {
                    ...base,
                    border: "1px solid #8F8F8F",
                    outline: "none",
                    color: "#575556"
                  }
                },
                option: (base, optionState) => {
                  return {
                    ...base,
                    color: optionState.isSelected ? "white" : "#575556"
                  }
                },
                singleValue: (base) => {
                  return {
                    ...base,
                    color: "#575556"
                  }
                },
              }}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isDisabled={isDisabled}
            />

            {error && (
              <p className="text-[red] text-[12px]">{error}</p>
            )}
          </label>
        </div>
      </p>
    </div>

  );
};

const SelectViewWithHeading = (props) => {
  return (
    <div className=" grid grid-cols-9 mt-4  md:mt-[2rem]   ">
      <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
          {props?.isFrom != "additionalDetails" &&
            <span className="text-[#D10A11] ml-1"> *</span>
          }
        </p>
      </div>
      <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">
        <p className="  ">
          <div className="flex ">
            <label className=" select font-bold text-[#575556] w-full ">
              <select
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                onSelect={props.onChange}
                disabled={props?.disabled}
                className={`w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px] ${props?.disabled ? "!cursor-not-allowed" : "cursor-pointer"}`}
              >
                <option value="" disabled>Select</option>
                {props?.options?.map((e, i) =>
                  e.heading === "Y" ? (
                    <option key={e.id} value={e.id} className="font-bold " disabled>
                      {e.name}
                    </option>
                  ) : (
                    <option key={e.id} value={e.id} className="ml-4 ">
                      {e.name}
                    </option>
                  )
                )}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

const HoroscopeEditTextView = (props) => {
  return (
    <div className={props?.menu == "afterHoroscope" ? "flex flex-col  mt-4 md:mt-0 lg:mt-[2rem]     pr-[1rem] pt-[1rem]"
      : props?.menu == "addhoroscope" ? " mt-4  md:mt-[2rem] md:mb-[2rem] lg:mb-[3rem] " :
        props?.menu == "horoscope" ? " " :
          "flex flex-col md:grid md:grid-cols-9 mt-4  md:mt-[2rem]    pr-[1rem] pt-[1rem]"}>
      <div className={props?.menu == "afterHoroscope" ? "md:col-span-3 flex items-center " : "md:col-span-3 flex items-center pl-[1rem]"}>
        <p className={props?.menu == "afterHoroscope" ? " text-[15px] pb-2 md:text-[15px] lg:text-[15px] flex text-[#000000] font-semibold   "
          : props?.menu == "horoscope" ? "text-[13px]   flex text-[#000000] font-semibold " : "text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "
        }>
          {props.title}
        </p>
      </div>
      <div className={props?.menu == "afterHoroscope" ? "md:col-span-6  w-full " :
        props?.menu == "horoscope" ? "py-2 md:col-span-6 md:pl-[1rem] pl-[1rem] w-full" : "md:col-span-6 md:pl-[1rem] pl-[1rem] w-full "}>
        <p className={props?.menu == "afterHoroscope" ? "text-[15px] font-semibold  text-[#656565]"
          : props?.menu == "horoscope" ? "text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px]" : "text-sm font-bold text-[#656565]"}>{props.value}</p>
        {/* {props.isPaid
         ? <p className="text-sm font-bold">{props.value}</p>
         :<p className="w-full ">
          <input
            type={props.inputType}
            id={props.inputID}
            name={props.inputName}
            value={props.value}
            className=" border cursor-not-allowed bg-white border-[#8F8F8F] text-sm  rounded-[3px] h-[35px]  block w-full md:p-[0.4rem] lg:p-2.5  font-bold    "
            placeholder=""
            autoComplete="off"
            disabled="disabled"
            onChange={props.onChange}
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
        </p>} */}
      </div>
    </div>
  );
};
const HoroscopeSelectView = (props) => {

  return (
    <div className={props?.menu === "after" ? "flex flex-col  mt-4  md:mt-[2rem]   md:mb-[2rem] lg:mb-[2rem] pr-[1rem]"
      : props?.menu == "addhoroscope" ? "" : "flex flex-col md:grid md:grid-cols-9 mt-4  md:mt-[2rem]   md:mb-[2rem] lg:mb-[3rem] pr-[1rem]"}>
      <div className={props?.menu === "after" ? "md:col-span-3 flex items-center " : "md:col-span-3 flex items-center  pl-[1rem] "}>
        <p className={props?.menu === "after" ? " text-[15px]  pb-1 md:text-[15px] lg:text-[15px] flex text-[#000000] font-semibold   " : props?.menu == "addhoroscope" ?
          "text-[13px]  flex text-[#000000] font-bold" : "text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "}>
          {props.title}<span className="text-[#D10A11]">*</span>
        </p>
      </div>
      <div className={props?.menu === "after" ? "md:col-span-6  " : "pl-[1rem]  md:col-span-6 md:pl-[1rem] "}>
        <p className="  ">
          {props?.menu === "after" ?
            <label className="   text-[#575556] w-full ">
              <span className="relative float-right top-[29px] md:top-[25px] right-[18px] arrow-clickabale"><img className="w-[13px]" src={RightArrow} alt="" /></span>

              <select
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                placeholder={props.placeholder}
                className="chartstyle w-full md:p-[0.9rem] h-[50px] p-2 lg:p-2.5  border border-[#D9D9D9] text-[#8F8F8F] text-[13px]  md:text-[14px] lg:text-[15px] bg-white rounded-[8px] "
              >

                <option value="" disabled selected >Select</option>
                {props?.options?.map((e, i) => (
                  <option value={e.id} selected={e.selected === "Y" && true}>
                    {e.name}
                  </option>
                ))}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
            :
            <label className=" select font-bold text-[#575556] w-full ">
              <select
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                placeholder={props.placeholder}
                className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold text-[13px]  md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
              >
                <option value="" disabled selected >Select</option>
                {props?.options?.map((e, i) => (
                  <option value={e.id} selected={e.selected === "Y" && true}>
                    {e.name}
                  </option>
                ))}
              </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>

          }

        </p>
      </div>
    </div>
  );
};

const HoroscopeSearchView = (props) => {

  console.log(props.placeholder, "props.placeholder");
  return (
    <div className={props?.menu == "after" ? "flex flex-col  mt-4  pt-1 md:pt-0 md:mt-[2rem]   md:mb-[2rem] lg:mb-[2rem] pr-[1rem] "
      : props?.menu == "addhoroscope" ? "" : "flex flex-col md:grid md:grid-cols-9 mt-4  md:mt-[2rem]   md:mb-[2rem] lg:mb-[3rem] pr-[1rem] "}>
      <div className={props?.menu == "after" ? "md:col-span-3 flex items-center  " : "md:col-span-3 flex items-center  pl-[1rem] "}>
        <p className={props?.menu == "after" ? " text-[15px] pb-3 md:text-[15px] lg:text-[15px] flex text-[#000000] font-semibold   " : props?.menu == "addhoroscope" ? "text-[13px]   flex text-[#000000] font-semibold " : "text-[13px]  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   "}>
          {props.title}<span className="text-[#D10A11]">*</span>
        </p>
      </div>
      <div className={props?.menu == "after" ? "md:col-span-6  " : "pl-[1rem] md:col-span-6 md:pl-[1rem] "}>
        <p className="  ">
          {props?.menu === "after" ?
            <label className="text-[#575556] w-full ">

              <SingleSelectDropdown
                placeholder={props?.placeholder ?? "Select"}
                menu="afterReigister"
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                defaultValue={props?.defaultValue}
                option={props?.options}
                callBackSearch={props.callBackApi}
                suffixIcon={<img alt="" className="w-[13px]" src={RightArrow} />}
              // mode={"multiple"}
              />
              {/* <span className="relative float-right md:top-[-27px] top-[-31px] right-[15px] md:right-[24px] "><img className="w-[13px]" src={RightArrow} /></span> */}

              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>

            :
            <label className=" select font-bold text-[#575556] w-full ">
              <SingleSelectDropdown
                name={props.inputName}
                value={props.value}
                id={props.inputID}
                onChange={props.onChange}
                option={props?.options}
                callBackSearch={props.callBackApi}
                placeholder={props.placeholder}
              // mode={"multiple"}
              />
              {/* <input
            name={props.inputName}
            value={props.value}
            id={props.inputID}
            onChange={(e) => {
              if (e.target.value.length > 2) {
                props.callBackApi(e.target.value);
              }

              props.onChange(e);
            }}
            className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[12px] bg-white rounded-[8px] "
          >
          <datalist>
            {props?.options?.map((e, i) => (
              <option key={i} value={e.id}>
                {e.name}
              </option>
            ))}
          </datalist>
          </input> */}
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
          }

        </p>
      </div>
    </div>
  );
};

const SaveCancelButton = (props) => {

  return (
    <div className={`flex justify-center space-x-3  items-center  ${props?.isFrom == "Hobby" ? "mb-0 md:mb-[2rem] lg:mb-[2rem]"
      : props?.menu == "astroadd" ? " mt-[1rem] mb-0" : props?.type == "astropopup" ? "mt-[1rem] !mb-[1rem]" : " mt-[2rem] mb-[2rem]"}`}>
      {props?.isFrom == "Hobby" ?

        <button
          type="submit"
          onClick={props?.saveCallBack}
          className=" flex items-center justify-center text-white bg-[#D10A11] border border-[#D10A11] lg:w-[6rem] w-[5rem] whitespace-nowrap rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px]"
        >
          {props?.submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>Saving...</span></> : <span>Save</span>}
        </button>
        :
        <button
          type="submit"
          onClick={(e) => props?.menu == "astroadd" || props?.type == "astropopup " ? props?.saveCallBack(e) : null}
          className={`flex items-center justify-center text-white ${props?.onchangedata || props?.onchangefamilydata ? "bg-[#E78387] cursor-not-allowed" : "bg-[#D10A11] "}
                border border-[#D10A11] lg:w-[6rem] w-[5rem] whitespace-nowrap bg-[#D10A11]
           rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px]`}
        >
          {props?.submitStatus ? <><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt="" /><span>Saving...</span></> : <span>Save</span>}
        </button>
      }
      {props?.menu == "astroadd" || props?.type == "astropopup" ? "" :
        <button
          onClick={props.cancelCallback}
          className=" flex items-center justify-center text-[#575556] bg-white border border-[#707070] lg:w-[6rem] w-[5rem] whitespace-nowrap rounded-[5px] font-bold py-1 md:text-[12px] lg:text-[14px]"
        >
          Cancel
        </button>
      }
    </div>
  );
};

const CropButton = (props) => {
  return (
    <button
      onClick={() => {
        props.onClick();
      }}
      className=" flex items-center justify-center w-[2.5rem] h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold  py-[1rem] lg:py-5 text-[10px] md:text-[12px] lg:text-[14px]"
    >
      Crop
    </button>
  );
};
const EditButton = (props) => {
  useEffect(() => {
    console.log(props?.dashboard1Data?.editfromadmin, "props?.dashboard1Data");

  }, [props?.dashboard1Data])
  return (
    <div
      className=" flex justify-end pr-[1rem] mt-2 cursor-pointer"
      onClick={() => {
        // if(props?.dashboard1Data?.editfromadmin=="N"){
        props.editCallBack()

        // }
      }

      }
    >
      <div className="flex justify-between items-center ">
        <img alt="edit" className={`  w-[1.3rem]`} src={EditIcon} />
      </div>
      <p className={` md:text-[12px]  lg:text-[14px] flex items-center text-[#D10A11] font-semibold pl-2 `}>
        Edit
      </p>
    </div>
  );
};

const MyPlansTextView = (props) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <>
      {isV3User ?
        <div className={` ${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A] bg-[${isV3User ? props.backgroundColor : "#F1F1F1"}] font-semibold pl-[1.5rem] flex items-center h-[36px]`}>
            {props.title}
          </p>
          {/* //text-[#62ABFE] underline */}
          <p className={`col-span-5  text-[#575556]  bg-[${isV3User ? props.backgroundColor : "#F1F1F1"}] font-semibold pl-[1.5rem] flex items-center h-[36px]`}>
            {props.value}
          </p>
        </div>
        :
        <div className={` ${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A] bg-[#F1F1F1] font-semibold pl-[1.5rem] flex items-center h-[50px]`}>
            {props.title}
          </p>
          {/* //text-[#62ABFE] underline */}
          <p className={`col-span-5  text-[#575556]  bg-[${isV3User ? props.backgroundColor : "#F1F1F1"}] font-semibold pl-[1.5rem] flex items-center h-[50px]`}>
            {props.value}
          </p>
        </div>
      }
    </>
  );
};

export const MyPlansTextViewWithAction = ({ title, buttonText, onClick, backgroundColor, text }) => {
  const isV3User = localStorage.getItem("isV3User")
  return (
    <>
      {isV3User ?
        <div className={`${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : " grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A]  bg-[${backgroundColor}] font-semibold pl-[1.5rem] flex items-center h-[36px]`}>
            {title}
          </p>
          {/* //text-[#62ABFE] underline */}
          <div className="col-span-5 bg-[#F1F1F1] flex items-center justify-between h-[36px] pr-4 ">
            <p className="text-[#575556]  bg-[#F1F1F1] font-semibold pl-[1.5rem] flex items-center h-[36px]">
              {text}
            </p>
            <p onClick={onClick} className="cursor-pointer text-[#D10A11] font-bold">
              {buttonText}
            </p>
          </div>

        </div>
        :

        <div className={`${isV3User ? "grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[14px]" : " grid grid-cols-9 space-x-1 mt-1 text-[12px] md:text-[12px] lg:text-[16px] "}`}>
          <p className={`col-span-4 text-[#7A7A7A]  bg-[#F1F1F1] font-semibold pl-[1.5rem] flex items-center h-[50px]`}>
            {title}
          </p>
          {/* //text-[#62ABFE] underline */}
          <div className="col-span-5 bg-[#F1F1F1] flex items-center justify-end h-[50px] pr-4 pl-[1.5rem]">
            <p onClick={onClick} className="cursor-pointer text-[#D10A11] font-bold">
              {buttonText}
            </p>
          </div>

        </div>
      }
    </>
  );
};

const EduactionListTag = (props) => {
  return (
    props?.isFrom == "KM-lite" ?
      <div className="flex justify-center items-center border rounded-[8px] border-[#E9E9E9] bg-[#E9E9E9] gap-2 px-4 py-1.5">
        <div className=" font-semibold text-[#575556]">
          {props.text}
        </div>
        <div className="w-4 cursor-pointer">
          <img src={CloseIcon} alt="CloseIcon" className="w-4 h-4" onClick={() => props.callBackSingleSelect(props.id, false)} />
        </div>
      </div>
      :
      <div className="border rounded-[8px] border-[#D10A11] bg-[#D10A11] font-semibold text-white px-1 m-1">
        {props.text}
      </div>
  );
};

const StaticCircularProgressBar = ({
  value,
  profileStrengthRemaining,
  remainingProfileNav,
}) => {
  const radius = 45;
  const strokeWidth = 7;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (value / 100) * circumference;
  const [isShowDropDown, setSetShowDropDown] = useState(true);
  useEffect(() => { }, [isShowDropDown]);
  const { onClickTrack } = useAnalytics()

  const isV3User = localStorage.getItem("isV3User")

  return (
    <div className={isV3User ? "w-[60px] md:w-[100px] lg:w-[80px] xl:w-[100px] relative" : "w-[60px] lg:w-[100px] relative"}>
      <svg viewBox="0 0 100 100" style={{ transform: "rotate(-90deg)" }}>
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke="#ddd"
          strokeWidth={strokeWidth}
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke="#039e3c"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progress}
        />
      </svg>
      {profileStrengthRemaining && (
        <div className=" text-[16px] md:text-[12px] lg:text-[16px]"
          style={{
            position: "absolute",
            top: "16%",
            left: "16%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",

            // color: '#0074d9',
          }}
        // onMouseEnter={() => {
        //   setSetShowDropDown(true);
        // }}
        // onMouseLeave={() => {
        //   setSetShowDropDown(false);
        // }}

        >
          <button onClick={() => {
            setSetShowDropDown(true);
            onClickTrack(EventName.profileCompletionScoreButton, { [EventKeys.profiledropdown]: profileStrengthRemaining });
          }}>
            <img
              className=" w-4 h-4 md:w-[0.7rem] md:h-[0.7rem] lg:w-4 lg:h-4 cursor-pointer"
              alt=""
              src={profileGrayImage}
            />
          </button>

          <EditProfileDropDown
            isShowDropDown={isShowDropDown}
            profileList={profileStrengthRemaining}
            remainingProfileNav={remainingProfileNav}
            onClose={() => setSetShowDropDown(false)}
          />
        </div>
      )}
      <div
        className=" text-[16px] md:text-[12px] lg:text-[16px]"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontWeight: "bold",
          // fontSize: "16px",
          // color: '#0074d9',
        }}
      >
        {value}%
      </div>
    </div>
  );
};


const StaticlineProgressBar = ({
  value,
  profileStrengthRemaining,
  remainingProfileNav,
}) => {
  const radius = 45;
  const strokeWidth = 7;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (value / 100) * circumference;
  const [isShowDropDown, setSetShowDropDown] = useState(true);
  const isV3User = localStorage.getItem("isV3User");

  useEffect(() => { }, [isShowDropDown]);

  return (
    <div className="w-[19rem] lg:w-[100px] relative">

      {profileStrengthRemaining && (
        <div className="top-[0.2rem] w-[1.5rem] h-[1.5rem] text-[16px] md:text-[12px] lg:text-[16px]"
          style={{
            position: "absolute",

            left: isV3User ? "94%" : "100%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",

            // color: '#0074d9',
          }}
        // onMouseEnter={() => {
        //   setSetShowDropDown(true);
        // }}
        // onMouseLeave={() => {
        //   setSetShowDropDown(false);
        // }}
        >
          <img className=" w-[1.4rem] h-[1.4rem] md:w-[0.7rem] md:h-[0.7rem] lg:w-4 lg:h-4" alt="" src={profileGrayImage} onClick={() => setSetShowDropDown(true)} />
          <EditProfileDropDown
            isShowDropDown={isShowDropDown}
            profileList={profileStrengthRemaining}
            remainingProfileNav={remainingProfileNav}
            onClose={() => setSetShowDropDown(false)}
          />
        </div>
      )}

    </div>
  );
};


const EditProfileDropDown = (props) => {
  const [isshown, setIsShown] = useState(props.isShowDropDown ?? false);
  useEffect(() => { setIsShown(props.isShowDropDown) }, [props.isShowDropDown]);

  return (
    <div
      id="dropdown"
      className={`${isshown ? "" : "hidden"
        } z-10 absolute right-1 top-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-[9rem] md:w-44 dark:bg-gray-700`}
    >
      <div className="relative">
        <ul
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          {props?.profileList?.map((e, i) => (
            <li>
              <a
                onClick={() => {
                  // setIsShown(false);
                  props.remainingProfileNav(e);
                  props.onClose()
                }}
                className="block px-4 py-[2px] text-[10px] md:text-[14px] font-normal hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                {e}
              </a>
            </li>
          ))}
        </ul>
        <button className="absolute top-[-4px] right-[-5px] w-4 h-4" onClick={props.onClose}><img src={CancelIcon} alt="" /></button>
      </div>
    </div>
  );
};

export const TextIconButton = ({ onClick, text = "", iconSrc = EditIcon, className = "" }) => {
  return (
    <button
      className={`flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <img alt="" className="w-[14px] h-[14px] md:w-[20px] md:h-[20px]" src={iconSrc} />
      <p className="text-[12px] md:text-[14px] flex items-center text-[#D10A11] font-semibold pl-2 ">
        {text}
      </p>
    </button>
  );
};



function RawSelectInput({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  placeholder,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur
}) {
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #8F8F8F",
      outline: "none",
      color: "#575556",

    }),
    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "white" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),
    singleValue: (base) => ({
      ...base,
      color: "#575556",

    }),
  };

  return (
    <>
      <Select
        isSearchable={true}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        blurInputOnSelect
        onBlur={onBlur}
        defaultValue={defaultValue}
        // menuIsOpen={true}
        className="text-[14px]"
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
        }}
        styles={customStyles}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
      {error && (
        <p className="text-[red] text-[12px]">{error}</p>
      )}
    </>
  );
}

export default RawSelectInput;

const HobbyListTag = (props) => {
  return (
    props?.isFrom == "show" ?
      <div className="border rounded-[8px] border-[#707070] text-[#575556] p-2 m-1 tex-[14px]">
        {props.text}
      </div>
      :
      <div className={`border rounded-[8px] border-[#707070]  p-2 m-1 text-[14px] cursor-pointer ${props?.selected == "Y" ? "bg-[#F9DADB] text-black" : "text-[#575556]"}`} onClick={props?.onClick}>
        {props.text}
      </div>
  );
};

const BasicTextViewWithImgHobby = (props) => {
  return (
    <div className="grid md:grid lg:grid md:grid-flow-row lg:grid-flow-row grid-cols-6 md:grid-cols-12 lg:grid-cols-12 gap-0 md:gap-[3rem] lg:gap-4">
      <div className="bg-[#D10A11] flex items-center justify-center md:col-span-1 lg:col-span-1 w-[2.4rem] h-[2.4rem] md:w-[3rem] md:h-[3rem] lg:w-[3rem] lg:h-[3rem] rounded-[5rem] md:mx-1 lg:mx-3 my-4">
        <img className="w-6 h-6 md:w-6 md:h-6 lg:w-7 lg:h-7" src={props.image} alt="" />
      </div>
      <div className="pt-[1.2rem] md:pt-[1.2rem] lg:pt-[.9rem] px-0 md:px-4 lg:pl-4 leading-[17px] md:leading-[20px] lg:leading-[26px] col-span-5 md:col-span-8 lg:col-span-11 mp-habits-details -ml-[.2rem] md:ml-0 lg:ml-0 mp-hob-detail">
        <p className="text-[#575556] font-normal text-[12px] md:text-[13px] lg:text-[14px]">
          {props.title}
        </p>
        <span className="text-[#575556] text-[12px] md:text-[13px] lg:text-[14px] font-bold break-words">
          {commaWithSpace(props?.value)}
        </span>
      </div>
    </div>
  );
};

const BasicPinTextView = (props) => {
  //console.log(props.disabled, 'props.disabled')
  useEffect(() => {
    //console.log(props?.value,"othercaste");
  }, [props?.value])
  return (
    <div className="  items-center md:p-0">
      <div className="col-span-9 md:col-span-4 ">
        <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
          {props.title}
          {props.required && <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className="col-span-9 md:col-span-5 ">
        <p className={props?.inputID === "parentNumber" ? "RegPhone" : ""}>
          <input
            type={props.inputType}
            id={props.inputID}
            name={props.inputName}
            value={props?.value}
            className={`border-[1px] border-[#D9D9D9] ${props?.value ? "highlight shadow-[0_0_6px_#F18181] rounded-[.5rem]" : ""} text-[14px] text-[#575556]  rounded-[6px] h-[40px]  block w-full p-[0.4rem] lg:p-2.5   ` + (props.className ?? '')}
            placeholder=""
            autoComplete="off"
            onChange={props.onChange}
            disabled={props?.disabled}
            onKeyDown={props?.onKeyDown}
          />
          {props.error && (
            <p className="text-[red] text-[12px]">{props.error}</p>
          )}
          {props?.inputID === "parentNumber" && (
            props?.phoneNumberError != "" && (
              <p className="text-[red] text-[12px]">{props.phoneNumberError}</p>
            )
          )}
        </p>
      </div>
    </div>
  );
};

export const SelectAstroInput = ({
  name,
  onChange,
  options,
  value,
  defaultValue,
  error,
  title,
  required,
  getOptionLabel,
  getOptionValue,
  onBlur,
  isFrom,
  menu,
}) => {

  console.log(error, "error");

  const OthersToEnd = (castes) => {
    if (!castes || !Array.isArray(castes)) {
      return [];
    }
    const othersIndex = castes.findIndex((caste) => caste.name === "Others");
    const othersOption = othersIndex !== -1 ? castes.splice(othersIndex, 1)[0] : null;
    if (othersOption) {
      castes.push(othersOption);
    }

    return castes;
  }

  const customStyles = {
    control: (base) => {
      return {
        ...base,
        border: "1px solid #8F8F8F",
        outline: "none",
        color: "#575556"
      }
    },

    singleValue: (base) => {
      return {
        ...base,
        color: "#575556"
      }
    },

    option: (base, optionState) => ({
      ...base,
      color: optionState?.isSelected ? "#575556" : "#575556",
      backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "white" : "",
      // fontWeight: optionState?.data?.name?.toLowerCase() === "others" ? "bold" : "normal",
      position: optionState?.data?.name?.toLowerCase() === "others" ? "sticky" : "relative",
      bottom: optionState?.data?.name?.toLowerCase() === "others" ? "0" : "",
      "&:hover": {
        backgroundColor: optionState?.data?.name?.toLowerCase() === "others" ? "#f0f0f0" : base.backgroundColor,
      },
      cursor: optionState?.data?.name?.toLowerCase() === "others" ? "pointer" : "pointer",

    }),

  };
  return (
    <div className={`   md:mt-[2rem] ${menu == "packastro" ? "" : "grid grid-cols-9"} mt-4    `}>
      <div className={`col-span-9  md:col-span-4 flex ${menu == "packastro" ? "md:pl-[1rem] lg:pl-[1rem]" : "md:pl-[1rem] lg:pl-[2rem]"}  items-center   `}>
        <p className={` flex  ${menu == "packastro" ? "text-[#000000] md:text-[13px] lg:text-[14px] font-bold py-1" : "text-[#7A7A7A] md:text-[12px] lg:text-[14px]"}  font-semibold  `}>
          {title} {isFrom == "requestStarMessage" ? <></> : <span className="text-[#D10A11] ml-1"> *</span>}
        </p>
      </div>
      <div className={`col-span-9  md:col-span-5 ${menu == "packastro" ? "md:pr-[1rem] lg:pr-[1rem]" : "md:pr-[1rem] lg:pr-[4rem]"}    md:pl-[1rem]`}>
        <p className="  ">
          <div className="flex ">
            <label className={`font-bold ${menu == "packastro" ? "md:text-[12px]  lg:text-[12px]" : "md:text-[12px]  lg:text-[14px]"} w-full `}>
              <Select
                isSearchable={true}
                placeholder={title == "Raasi" ? options ? options?.length == 2 ? options[1].name : "Select" : "Select" : "Select"}
                name={name}
                value={value === undefined ? "null" : value}
                onChange={onChange}
                options={OthersToEnd(options)}
                blurInputOnSelect
                onBlur={onBlur}
                defaultValue={defaultValue}
                styles={customStyles}

                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <IoMdArrowDropdown className="mr-2" size={20} color="#575556" />
                }}
                // styles={{
                //   control:(base)=>{
                //     return {
                //       ...base,
                //       border:"1px solid #8F8F8F",
                //       outline:"none",
                //       color:"#575556"
                //     }
                //   },
                //   option:(base,optionState)=>{
                //     return {
                //       ...base,
                //       color:optionState.isSelected ?"white":"#575556"
                //     }
                //   },
                //   singleValue:(base)=>{
                //     return {
                //       ...base,
                //       color:"#575556"
                //     }
                //   },
                // }}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
              />

              {error && (
                <p className="text-[red] text-[12px]">{error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

export const SelectHobbyInput = (props) => {
  console.log(props.value, "props.valueprops.value");

  const isV3User = localStorage.getItem("isV3User")

  useEffect(() => {

  }, [props.placeholder])
  return (
    <div className={` md:grid md:grid-cols-9 ${isV3User ? "px-[.3rem] md:px-[1.4rem] lg:px-[1.4rem] mt-4 md:mt-[1rem]" : "mt-4 md:mt-[2rem]"} justify-center items-center  space-y-2 md:space-y-0`}>

      <div
        className={`${props?.page === "search" ? "col-span-2 flex items-center pr-[0.1rem] "
          : props.page == 'physical' ? `md:col-span-3 lg:col-span-4 col-span-9 ${isV3User ? "lg:pl-[1.4rem]" : "lg:pl-[1rem]"}  ` : `md:col-span-3 lg:col-span-4 col-span-9 `}`} >
        <p className={isV3User ? "  md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold   " : " md:text-[12px] lg:text-[14px] flex text-[#575556] font-semibold  "}>
          {props.title}
          {/* <!-- <span className="text-[#D10A11]">*</span> --> */}
        </p>
      </div>
      <div
        className={`${props?.page === "search" ? "col-span-7 "
          : props.page == 'physical' ? `md:col-span-6 lg:col-span-5 col-span-9  ${isV3User ? "md:pl-[1.2rem] lg:pl-[2rem]" : "md:pl-[1.2rem] lg:pl-[2rem]"} ` : `md:col-span-6 lg:col-span-5 col-span-9 md:pr-[1rem]   md:pl-[1rem]`}`}
      >
        <div className="flex ">
          <label className=" select font-bold text-[#575556] w-full ">

            <SelectDropdownUtil
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              placeholder={props.placeholder != null ? props.placeholder : `Any ${props.title}`}
              onChange={props.onChange}
              option={props?.options}
            // mode={"multiple"}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

const SelectOccupationWithHeading = (props) => {
  return (
    <div className={` ${(props?.fieldType >= 27 && props?.fieldType <= 30) || props?.fieldType == 44 ? "  md:mt-[2rem]  mt-4" : props?.isFrom == "additionalFields" ? "md:mt-[1rem] lg:mt-[1rem] xl:mt-[1.3rem]  mt-4" : props?.fieldType == 19 ? '' : "grid grid-cols-9 md:mt-[2rem]  mt-4"}    `}>
      <div className={`col-span-9 md:col-span-4  md:pl-[1rem] lg:pl-[1rem]`}>
        <p className={`  text-[#575556] md:text-[12px] lg:text-[13px] xl:text-[15px] font-Poppins-SemiBold pb-1`}>
          {props?.title}
        </p>
      </div>
      <div className={`col-span-9 md:col-span-5 md:px-[1rem] mt-1`}>
        <p className="  ">
          <div className="flex ">
            <label className={` ${(props?.fieldType >= 67 && props?.fieldType <= 84) ? "AdditionalFamily md:text-[12px] lg:text-[13px] xl:text-[15px] text-[#575556]" : "md:text-[12px]  lg:text-[14px] font-bold"} w-full `}>                <select
              name={props.inputName}
              value={props.value}
              id={props.inputID}
              onChange={props.onChange}
              onSelect={props.onChange}
              className="w-full md:p-[0.4rem] lg:px-2.5 h-[35px]  border border-[#8F8F8F] text-[#575556] md:text-[12px] lg:text-[14px] bg-white rounded-[3px] lite-scrollbar"
            >
              <option value="" disabled>Select</option>
              {props?.options?.map((e, i) =>
                e.heading === "Y" ? (
                  <option key={e.id} value={e.id} className="font-bold " disabled>
                    {e.name}
                  </option>
                ) : (
                  <option key={e.id} value={e.id} className="ml-4 ">
                    {e.name}
                  </option>
                )
              )}
            </select>
              {props.error && (
                <p className="text-[red] text-[12px]">{props.error}</p>
              )}
            </label>
          </div>
        </p>
      </div>
    </div>
  );
};

export {
  StaticCircularProgressBar,
  EduactionListTag,
  SelectViewWithHeading,
  HoroscopeSearchView,
  HoroscopeEditTextView,
  HoroscopeSelectView,
  CropButton,
  MyPlansTextView,
  EditButton,
  BasicTextViewWithImg,
  SaveCancelButton,
  ProfileMenuItem,
  BasicTextView,
  BasicEditTextView,
  BasiSelectView,
  StaticlineProgressBar,
  RawSelectInput,
  HobbyListTag,
  BasicTextViewWithImgHobby,
  BasicPinTextView,
  SelectOccupationWithHeading
};
