import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import { useFormik } from "formik";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { POST, GET } from "../../Services/api_services";
import {
  EventKeys,
  blockInvalidCharForNumber,
} from "../../Constants/constants";
import config from "../../config";
import { validateNumber } from "../../Utils";
import Constants, { EventName } from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import {
  FamilyBasicEditTextView,
  FamilySelectCurrencyInput,
  SelectFamilyInput,
  SelectFamilyNumberInput, 
  SelectRelationInput,
} from "./PartnerPreferenceUtils";
import RouteSegments from "../../Routes/RouteSegment";
import AboutFamily from "./AboutFamily";
import SiblingsPage from "./SiblingsPage";
import LoaderGif from "../../Gif/loader.gif"

const FamilyDetails = (props) => {

  const { loginDetail, logout } = useContext(LoginContext);
  const siblingsRef = useRef(null);
  const isSiblingsScroll = useRef(true)
  const { onClickTrack } = useAnalytics();

  const isCommunitySite = localStorage.getItem(
    Constants.loginLocalStorageKeys.isCommunitySite
  );

  useEffect(() => {
    // Scroll to the top of the page when this component is rendered
    window.scrollTo(0, 0);
  }, []);
  
  const [familyStatusDropDown, setfamilyStatusDropDown] = useState([]);
  const [familyTypeDropDown, setfamilyTypeDropDown] = useState([]);
  const [familyValuesDropDown, setfamilyValuesDropDown] = useState([]);
  const [parentsIsdCodeDropDown, setparentsIsdCodeDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([
    { value: 5, label: "Mother" },
    { value: 6, label: "Father" },
  ]);
  
  const [isDisabled, setIsDisabled] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [isEditProfileLoading, setIsEditProfileLoading] = useState(true);
  const [isParentNumberChanged, setIsParentNumberChanged] = useState(false);
  const [isFamilyLoading, setIsFamilyLoading] = useState(true);
  const [isFamilyShown, setIsFamilyShown] = useState(true);
  const [isFamilyDisabled, setIsFamilyDisabled] = useState(false);
  const [showSiblingsDetails,setShowSiblingsDetails] = useState(false)

  const [familyinfofreshData, setfamilyinfofresh] = useState();
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [dropDown, setDropDownData] = useState("");
  const [isParentNumberTouched, setIsParentNumberTouched] = useState(false);

console.log(isFamilyShown,"isFamilyShown");


  const isShowFamily = editMyProfileData?.isAllFamilyDetailsFilled;

  const handleSkipClick = () => {
    if (siblingsRef.current) {
      siblingsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setIsFamilyDisabled(true);
    setShowSiblingsDetails(true)
  };

  const formik = useFormik({
    initialValues: {
      familyStatus: editMyProfileData?.familyStatus
        ? editMyProfileData?.familyStatus[0].id
        : "",
      familyType: editMyProfileData?.familyType
        ? editMyProfileData?.familyType[0].id
        : "",
      familyValues: editMyProfileData?.familyValues
        ? editMyProfileData?.familyValues[0].id
        : "",
      parentsIsdCode: editMyProfileData?.parentsIsdCode
        ? editMyProfileData?.parentsIsdCode
        : "",
      parentNumber: editMyProfileData?.parentsMobileNumber
        ? editMyProfileData?.parentsMobileNumber
        : "",
      relationshipId: editMyProfileData?.relationshipId
        ? editMyProfileData?.relationshipId
        : "",
      parentsMonthly: editMyProfileData?.parentsMonthly
        ? editMyProfileData?.parentsMonthly
        : "",
      parentsCurrency: editMyProfileData?.parentsCurrency?.currency
        ? currencyDropDown?.find((e) => e.label === editMyProfileData?.parentsCurrency?.currency)?.value
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      familyStatus: Yup.string(),
      familyType: Yup.string(),
      familyValues: Yup.string(),
      parentsIsdCode: Yup.string(),
      relationshipId: Yup.string().nullable()
        .test(
          "valid-relationshipId",
          "Please select relation",
          function (value) {
            const { parentsIsdCode, parentNumber } = this.parent;

            // If the relationshipId is empty (non-mandatory), no validation is required
            if (value) {
              return true;
            } else if (parentNumber){
              return this.createError({
                message: "Please select relation",
                path: "relationshipId",
              });
            }


            return true;
          }
        ),
      parentNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { parentsIsdCode } = this.parent;
            console.log(value?.length,'value?.length');
            if (parentsIsdCode == "+91") {
              if(value?.length == 0 || value?.length == undefined){
                return true;
              }else if (value?.length !== 10) {
                return this.createError({
                  message: "Please enter a valid Mobile Number",
                  path: "parentNumber",
                });
              }
            } else {
              if(value?.length == 0 || value?.length == undefined){
                return true;
              }else if (value?.length < 7 || value?.length > 15) {
                return this.createError({
                  message: "Please enter a valid Mobile Number",
                  path: "parentNumber",
                });
              }
            }
            return true;
          }
        ),
      parentsMonthly: Yup.string(),
    }),
    onSubmit: (values) => {
      if(!formik.dirty){
        setSubmitStatus(false);
      }else{
        setSubmitStatus(true);
        apiCallFamily(values);
        handleSkipClick()
      }

    },
  });


  // useEffect(() => {
  //   if (editMyProfileData) {
  //     if(editMyProfileData?.isAllFamilyDetailsFilled){
  //       formik.setValues({
  //         familyStatus: editMyProfileData.familyStatus[0]?.id || "",
  //         familyType: editMyProfileData.familyType[0]?.id || "",
  //         familyValues: editMyProfileData.familyValues[0]?.id || "",
  //         parentsIsdCode: editMyProfileData?.parentsIsdCode,
  //         parentsMonthly: editMyProfileData?.parentsMonthly,
  //         relationshipId: editMyProfileData?.relationshipId,
  //         parentNumber: editMyProfileData?.parentsMobileNumber,
  //         parentsCurrency: editMyProfileData?.parentsCurrency?.currency,
  //       });
  //     }
  //   }
  // }, [editMyProfileData]);

  useEffect(() => {
    if(dropDown){
      setfamilyStatusDropDown( dropDown?.familyStatusData.map(item => ({
        value: item.id,  
        label: item.name
      })) || []);
      setfamilyTypeDropDown(dropDown?.familyTypeData.map(item => ({
        value: item.id,  
        label: item.name
      })) || []);
      setfamilyValuesDropDown(dropDown?.familyValuesData.map(item => ({
        value: item.id,  
        label: item.name
      })) || []);
      setparentsIsdCodeDropDown(dropDown?.isdcode.map(item => ({
        value: item.countryId,  
        label: item.isdcode + " - "+item.country
      })) || []);
      setcurrencyDropDown(dropDown?.currencyData.map(item => ({
        value: item.id,  
        label: item.name
      })) || []);
    }
  }, [dropDown]);

  useEffect(() => {}, [
    familyStatusDropDown,
    familyTypeDropDown,
    familyValuesDropDown,
    parentsIsdCodeDropDown,
    currencyDropDown,
    relationDropDown,
  ]);

  useEffect(() => {
    apiCallEditProfile();
    apiCallDropDown();

  }, []);

  const apiCallEditProfile = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
    };
    setIsEditProfileLoading(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editMyProfileUrl()}`,
      request
    );

    if (statusCode === 200) {
      setEditMyProfielData(data?.data);
    } else if (statusCode === 401) {
      logout();
    }
    setIsEditProfileLoading(false);
  };

  const apiCallFamily = async (value) => {
    const request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(
        Constants.loginLocalStorageKeys.loginDevice
      ),
      FamilyStatusId: value?.familyStatus,
      familyTypeId: value?.familyType,
      familyValuesId: value?.familyValues,
      parentsMobileNumber: value?.parentNumber,
      parentsIsdcode: dropDown?.isdcode.filter(val => val?.countryId == value?.parentsIsdCode || val?.isdcode == value?.parentsIsdCode).at(0)?.isdcode,
      parentsMonthly: value?.parentsMonthly,
      parentsCurrency:value?.parentsCurrency,
      relationshipId: value?.relationshipId,
    };
    

    const { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editfamilyinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false);
        setIsParentNumberChanged(false);
        setIsFamilyShown(false);
        onClickTrack(EventName.profileFamilySaveButton, {
          [EventKeys.profileFamily]: value,
        });
        handleSkipClick()
        apiCallEditProfile();
        setIsDisabled(true)
      } else {
        setSubmitStatus(false);
        setPhoneNumberError(data?.data?.statusMessage);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallDropDown = async () => {
    const url = `${config.api.API_URL}${EndPoints.familyinfofreshUrl()}`;
    const { statusCode, data } = await GET(url);

    if (statusCode === 200) {
      console.log(data.data, "data.data");
      setDropDownData(data.data);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const selectedFamilyStatus = useMemo(
    () =>
      familyStatusDropDown?.filter(
        (option) => option.value == formik.values.familyStatus
      ).at(0)?.value,
    [familyStatusDropDown, formik.values.familyStatus]
  );

  const selectedFamilyType = useMemo(
    () =>
      familyTypeDropDown?.filter(
        (option) => option.value == formik.values.familyType
      ).at(0)?.value,
    [familyTypeDropDown, formik.values.familyType]
  );

  const selectedFamilyValues = useMemo(
    () =>
      familyValuesDropDown?.filter(
        (option) => option.value == formik.values.familyValues
      ).at(0)?.value,
    [familyValuesDropDown, formik.values.familyValues]
  );

  const selectedParentsIsdCode = useMemo(
    () =>
      parentsIsdCodeDropDown?.filter(
        (option) => option.value == formik.values.parentsIsdCode
      ).at(0)?.value,
    [formik.values.parentsIsdCode, parentsIsdCodeDropDown]
  );
  const selectedParentsCurrenyCode = useMemo(
    () =>
      currencyDropDown?.filter((option) => option.value == formik.values.parentsCurrency).at(0)?.value,
    [formik.values.parentsCurrency, currencyDropDown]
  );
  const selectedRelation = useMemo(
    () =>
      relationDropDown?.filter(
        (option) => option.value == formik.values.relationshipId
      ).at(0)?.value,
    [relationDropDown, formik.values.relationshipId]
  );

  console.log(formik.values.parentsIsdCode,'selectedParentsCurrenyCode');
  useEffect(() =>{
    if(formik?.values.familyStatus || formik?.values?.familyType || formik?.values?.familyValues || formik?.values?.parentNumber || formik?.values?.parentsMonthly || formik?.values?.relationshipId){
     setIsDisabled(false); // Enable submit button
    } 
  },[formik?.values.familyStatus, formik?.values?.familyType, formik?.values?.familyValues,, formik?.values?.parentNumber, formik?.values?.parentsMonthly, formik?.values?.relationshipId])

  return (
    <div>
      <div className=" mx-[1.75rem] md:mx-[5.75rem] mt-[3rem] mb-5 shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_1px_5px_13px_-1px_rgba(45,78,255,0.15)]">
        <div className="p-[1.25rem] md:p-[2rem] bg-[#FFFFFF]">
          {false  && isFamilyShown ? <></> :
          <div className="mx-2 lg:pb-[2rem] md:pb-[1rem] pb-[0.5rem]">
            <div className="mt-[2rem] md:mt-[2rem] lg:mt-[2rem] lg:pl-[2rem]">
              <div className="space-y-2">
                <p className="text-[#D63838] font-bold text-[18px] md:text-[18px] lg:text-[22px]">
                  Family Details
                </p>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="p-0 md:p-0 lg:px-[2rem]">
                {/* <SelectFamilyInput
                  name="familyStatus"
                  title="Family Status"
                  value={formik.values.familyStatus}
                  onChange={(selected) =>{
                    formik.setFieldValue("familyStatus", selected)
                  }
                  }
                  error={formik.errors.familyStatus}
                  defaultValue={formik.values.familyStatus}
                  options={[{ value: null, label: "Select", disabled: true },...familyStatusDropDown]}
                  menu="aferRegister"
                  placeholder="Select Family Status"
                  disabled={isFamilyDisabled}
                /> */}
                <SelectFamilyInput
                  name="familyStatus"
                  title="Family Status"
                  value={selectedFamilyStatus}
                  onChange={(selected) =>{
                    formik.setFieldValue("familyStatus", selected)
                  }
                  }
                  error={formik.errors.familyStatus}
                  defaultValue={formik.values.familyStatus}
                  options={[{ value: null, label: "Select", disabled: true },...familyStatusDropDown]}

                  menu="aferRegister"
                  placeholder="Select Family Status"
                  disabled={isFamilyDisabled}
                  
                />

                <SelectFamilyInput
                  name="familyType"
                  title="Family Type"
                  value={selectedFamilyType}
                  onChange={(selected) =>{
                    formik.setFieldValue("familyType", selected)
                  }}
                  error={formik.errors.familyType}
                  defaultValue={formik.values.familyType}
                  options={[
                    { value: null, label: "Select", disabled: true },
                    ...familyTypeDropDown,
                  ]}
                  menu="aferRegister"
                  placeholder="Select Family Type"
                  disabled={isFamilyDisabled}
                  
                />

                <SelectFamilyInput
                  name="familyValues"
                  title="Family Values"
                  value={selectedFamilyValues}
                  onChange={(selected, ev) => {
                    formik.setFieldValue("familyValues", selected);
                  }}
                  error={formik.errors.familyValues}
                  defaultValue={formik.values.familyValues}
                  options={[
                    { value: null, label: "Select", disabled: true },
                    ...familyValuesDropDown,
                  ]}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menu="aferRegister"
                  placeholder="Select Family Value"
                  disabled={isFamilyDisabled}
                  
                />

                <div className=" grid grid-cols-9 mt-[1rem] md:mt-[2rem] lg:mt-[2rem]">
                  <div className="md:col-span-3 col-span-9 flex items-center  ">
                    <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F]  font-semibold  ">
                      Parent's Mobile Number
                    </p>
                  </div>
                  <div className="col-span-1 hidden md:flex lg:flex justify-center items-center">
                    <p className="font-bold">:</p>
                  </div>
                  <div className="col-span-9 md:col-span-5 lg:col-span-4 md:pl-[1rem]">
                      <div className="grid grid-cols-10 md:grid-cols-12 lg:grid-cols-12 gap-6 md:gap-4 lg:gap-0 pt-2 md:pt-0 lg:pt-0">
                        <div className="col-span-3 md:col-span-3 lg:col-span-3 2xl:col-span-2">
                        <SelectFamilyNumberInput
                        menu="famiylcode"
                          name="parentsIsdCode"
                          title="Parent's IsdCode"
                          value={selectedParentsIsdCode ?? formik?.values?.parentsIsdCode}
                          onChange={(selected, ev) => {
                            formik.setFieldValue(
                              "parentsIsdCode",
                              selected
                            );
                          }}
                          error={formik.errors.parentsIsdCode}
                          defaultValue={formik?.values?.parentsIsdCode}
                          options={[
                            { value: null, label: "Select", disabled: true },
                            ...parentsIsdCodeDropDown,
                          ]}
                          className="!w-[5rem] md:!w-[4rem] !lg:w-[5.4rem] xl:!w-[5.4rem]"
                          disabled={isFamilyDisabled}

                        />
                       </div>
                       <div className="col-span-7 md:col-span-5 lg:col-span-5">
                        <FamilyBasicEditTextView
                          title={"Parent's MobileNumber"}
                          inputID={"parentNumber"}
                          inputName={"parentNumber"}
                          value={formik.values.parentNumber}
                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e);
                              setIsParentNumberChanged(true);
                              setIsParentNumberTouched(true)
                            }
                          }}
                          phoneNumberError={phoneNumberError}
                          error={isParentNumberTouched ? formik.errors.parentNumber : ''}
                          inputType={"tel"}
                          onKeyDown={blockInvalidCharForNumber}
                          menu="familyno"
                          disabled={isFamilyDisabled}

                        />

                        </div>
                        
                          <div className="col-span-10 md:col-span-4 lg:col-span-3 2xl:col-span-4">
                          <SelectFamilyInput
                          name="relationshipId"
                          menu="aferRegister"
                          type="relation"
                          value={selectedRelation}
                          onChange={(selected,ev) => {
                            formik.setFieldValue("relationshipId", selected);
                          }}
                          error={isParentNumberTouched ? formik?.errors?.relationshipId : ''}
                          defaultValue={formik?.values?.relationshipId}
                          options={[
                            { value: null, label: "Select", disabled: true },
                            ...relationDropDown
                          ]}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          placeholder="Relation"
                          disabled={isFamilyDisabled}
                          
                        />
                            
                         
                      </div>
                      </div>
                  </div>

                </div>
                <div className=" flex md:grid grid-cols-9">
                  <div className="flex col-start-5 col-span-5 lg:pr-[5rem] md:pt-5 md:pl-4">
                    <p className={`text-[12px] text-[#A5A5A5] ${formik.errors.parentNumber || formik?.errors?.relationshipId ? 'lg:pt-[0.5rem] md:pt-[0.1rem]' : ''}`}>
                      * This number will be visible to Matches. Matches can also
                      contact you on this number
                    </p>
                  </div>
                </div>


                <div className=" grid grid-cols-9 mt-[2rem] ">
                  <div className="md:col-span-3 col-span-9 flex items-center  ">
                    <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F]  font-semibold  ">
                      Parent's Monthly Income
                    </p>
                  </div>
                  <div className="col-span-1 hidden md:flex lg:flex justify-center items-center">
                    <p className="font-bold">:</p>
                  </div>
                  <div className="md:col-span-5 lg:col-span-4 col-span-9 xl:pr-[2rem] lg:pr-[1rem]  md:pl-[1rem]">
                    <p className="pt-2 md:pt-0 lg:pt-0">
                      <div className="flex lg:space-x-2 xl:space-x-4 space-x-3">
                        <SelectFamilyNumberInput
                          menu="famiylcode"
                          name="parentsCurrency"
                          id="parentsCurrency"
                          title="parentsCurrency"
                          error={formik?.errors?.parentsCurrency}
                          defaultValue={formik?.values?.parentsCurrency}
                          value={formik?.values?.parentsCurrency}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("parentsCurrency", selected);
                          }}
                          className="  w-full border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px]"
                          
                          options={[
                            { value: null, label: "Select", disabled: true },
                            ...currencyDropDown,
                          ]}
                          getOptionLabel={(option) =>
                            `${option.name ? option.name : option.currency}`
                          }
                          getOptionValue={(option) => option.id}
                          disabled={isFamilyDisabled}
                        />

                        <p className=" flex items-center w-[100%] pt-[1px] RegPhone">
                          <input
                            inputID={"parentsMonthly"}
                            inputName={"parentsMonthly"}
                            type="tel"
                            onChange={(e) => {
                              if (validateNumber(e.target.value)) {
                                formik.handleChange(e);
                              }
                            }}
                            id="parentsMonthly"
                            value={formik.values.parentsMonthly}
                            className=" RegPhone lg:!w-[100%] xl:!w-[97%] !w-[100%] md:!w-[100%] border-none bg-[#E6E6E6] rounded-[8px] h-[46px] p-4 block  md:p-4 lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[14px]"
                            disabled={isFamilyDisabled}
                          />
                        </p>
                        
                      </div>
                      {formik.errors.parentsMonthly && (
                          <p className="text-[red] text-[12px]">
                            {formik.errors.parentsMonthly}
                          </p>
                        )}
                    </p>
                  </div>
                </div>
                <div className="flex  mt-7 items-center justify-around  py-4">
                
                  <div></div>
                  <div className="">
                    <button
                      type="submit"
                      className={`text-[14px]  text-white px-4 lg:px-[2.5rem] py-2.5 rounded-md ${!formik.dirty ? "bg-[#DA3535] opacity-50 cursor-not-allowed" : "bg-[#DA3535] cursor-pointer"}`}
                      disabled={!formik.isValid || formik.isSubmitting}
                    >
                      {submitStatus ?<div className="flex"><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>Submitting...</span></div> : "Submit & Continue"}
                    </button>
                  </div>
                  
                  
                  {isCommunitySite ? null : (
                    
                    <div className="pl-[6rem] md:pl-[12rem]">
                      {isFamilyDisabled ? '' :
                      <button
                        type="button"
                        onClick={handleSkipClick}
                        className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline"
                      >
                        Skip
                      </button>}
                    </div>
                  )}
                 
                </div>
              </div>
            </form>
          </div>
          }
          {showSiblingsDetails &&
          <div className="lg:pt-[5rem] md:pt-[1rem] pt-0">
            <div ref={siblingsRef}>
              <p ref={(ref) => {
                  if (isSiblingsScroll.current) {
                      ref?.scrollIntoView({ behavior: "smooth" })
                      isSiblingsScroll.current = false
                  }
              }}>
              <AboutFamily 
                editMyProfileData={editMyProfileData}
                parentsIsdCodeDropDown={parentsIsdCodeDropDown}
                isdcode={dropDown?.isdcode}
              />
              </p>
            </div>
            {/* <div >
              <SiblingsPage />
            </div>  */}
            
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default FamilyDetails;
