import React, { useContext, useEffect, useState } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  SelectViewWithHeading,
} from "../../Components/Edit Profile/EditProfileUtills";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FamilyBasicEditTextView,
  FamilyEditTextView,
  SelectFamilyInput,
  SelectFamilyNumberInput,
  SelectFamilyViewWithHeading,
} from "./PartnerPreferenceUtils";
import { validateNumber } from "../../Utils";
import { GET, POST } from "../../Services/api_services";
import config from "../../config";
import EndPoints from "../../Constants/EndPoints";
import { LoginContext } from "../../Contexts/LoginContext";
import Constants, { assetOptions, countOptions } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";
import LoaderGif from "../../Gif/loader.gif"
import axios from "axios";


const AboutFamily = (props) => {

  const { loginDetail, logout } = useContext(LoginContext);
  const navigate = useNavigate();


  const [additionalFamDetail, setadditionalFamDetail] = useState([]);
  const [occupationData, setOccupationData] = useState([]);

  const [noSiblings, setNoSiblings] = useState(false);
  const [siblingsDisabled, setSiblingsDisabled] = useState(false);


  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  const [marriedBroCountOptions, setMarriedBroCountOptions] = useState(countOptions);

  const [marriedSisterCountOptions, setMarriedSisterCountOptions] = useState(countOptions);


  const familyInfoFreshAPI = async () =>{

    const url = `${config.api.API_URL}${EndPoints.additionalFamilyinfofreshUrl()}`;

    let { statusCode, data } = await GET(url);

    if (statusCode === 200) {
      setadditionalFamDetail(data?.data);
    } else if (statusCode === 401) {
      logout();
    }
  }


  useEffect(()=>{
    familyInfoFreshAPI();
  },[])

  const saveAdditionalFamilyInfo = async (values) => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),

      fathersName:values?.fathersName,
      mothersName:values?.mothersName,
      
      mothersOccupation:values?.mothersOccupation,
      fathersOccupation:values?.fathersOccupation,
      
      sisters:values.haveSiblings ? null : values?.sisterCount,
      brothers:values.haveSiblings ? null : values?.brotherCount,
      
      marriedSisters:values.haveSiblings ? null : values?.marriedSisterCount,
      marriedBrothers:values.haveSiblings ? null : values?.marriedBrotherCount,
      
      fathersIsdcode:additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.fisdCode || item.isdcode == values?.fisdCode).at(0)?.isdcode,
      fathersMobilenumber:values?.fMobileNumber,
      mothersIsdcode:additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.misdCode || item.isdcode == values?.misdCode).at(0)?.isdcode,
      mothersMobilenumber:values?.motherMobileNumber,
      
      brothersMobilenumber:values.haveSiblings ? null : values?.brotherNumber,
      sistersMobilenumber:values.haveSiblings ? null : values?.sisterNumber,
      
      sistersIsdcode:values.haveSiblings ? null : additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.sisIsdCode || item.isdcode == values?.sisIsdCode).at(0)?.isdcode,
      brothersIsdcode:values.haveSiblings ? null : additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.broIsdCode || item.isdcode == values?.broIsdCode).at(0)?.isdcode,
      
      isMotherAsParentsnumber:values?.isMother ? "Y" : "N",
      isfatherAsParentsnumber:values?.isFather ? "Y" : "N",
      assetIds: values?.assetInfo,

      editedFrom:"afterRegistration",
      isParentsMobilenumberfilled:props?.editMyProfileData?.isParentsMobilenumberfilled,
      ishavenosiblings:values?.haveSiblings ? "Y" : "N"


    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.saveAdditionInfo()}`,
      request
    );

    if (statusCode === 200) {
      handleSkip();
    } else if (statusCode === 401) {
      logout();
    }
  };

  const parentNumber = props?.editMyProfileData?.parentsMobileNumber;
  const parentCurrency = props?.editMyProfileData?.parentsCurrency?.countryId;
  const showMother = props?.editMyProfileData?.relationshipId == "5";
  const showFather = props?.editMyProfileData?.relationshipId == "6";
  const parentISDcode = props?.editMyProfileData?.parentsIsdCode;


  const formik = useFormik({

    initialValues: {
      
      fathersName: "",
      fathersOccupation: null,
      fisdCode: showFather ? parentISDcode : "+91",
      fMobileNumber: showFather ?  parentNumber : "",
      isFather: showFather ? true : false,

      mothersName: "",
      mothersOccupation: null,
      misdCode:showMother ? parentISDcode : "+91",
      motherMobileNumber:showMother ? parentNumber : "",
      isMother: showMother ? true : false,

      haveSiblings:false,
      
      brotherCount: "",
      marriedBrotherCount:"",
      broIsdCode:"+91",
      brotherNumber:"",
      
      sisterCount:"",
      marriedSisterCount:"",
      sisIsdCode:"+91",
      sisterNumber:"",

      assetInfo:[],
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      fathersName: Yup.string(),
      fMobileNumber: Yup.string()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { fisdCode } = this.parent;
            if (fisdCode == "+91" || fisdCode == 499) {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "fMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "fMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { motherMobileNumber,brotherNumber,sisterNumber } = this.parent;
            if (value && (value == motherMobileNumber || value == brotherNumber || value == sisterNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "fMobileNumber",
              });
            }
            return true;
          }
        ).when("isFather", (isFather,schema) => {

          if (isFather && isFather[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
        motherMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { misdCode } = this.parent;
            if (misdCode == "+91" || misdCode == 499) {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "motherMobileNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "motherMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,brotherNumber,sisterNumber } = this.parent;
            if (value && (value == fMobileNumber || value == brotherNumber || value == sisterNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "motherMobileNumber",
              });
            }
            return true;
          }
        ).when("isMother", (isMother,schema) => {

          if (isMother && isMother[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
        brotherNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { broIsdCode } = this.parent;
            if (broIsdCode == "+91" || broIsdCode == 499) {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "brotherNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "brotherNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,motherMobileNumber,sisterNumber } = this.parent;
            if (value && (value == fMobileNumber || value == motherMobileNumber || value == sisterNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "brotherNumber",
              });
            }
            return true;
          }
        ),
        sisterNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { sisIsdCode } = this.parent;
            if (sisIsdCode == "+91" || sisIsdCode == 499) {
              if(value){
                if (value?.length !== 10) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "sisterNumber",
                  });
                }
              }
            } else {
              if(value){
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: "Please enter a valid Mobile Number",
                    path: "sisterNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber,brotherNumber,motherMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == brotherNumber || value == motherMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "sisterNumber",
              });
            }
            return true;
          }
        )
    }),

    onSubmit: (values) => {
      console.log(values);
      saveAdditionalFamilyInfo(values);
      // onClickTrack(EventName.profilebasicsaveButton);
    },
  });
console.log(formik, 'ppformik');
  const onNameChange = (e,field) => {
    let text = e.target.value ?? "";
    text = text
      .trimStart()
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/  +/g, " ");
    formik.setFieldValue(field, text);
  };
  

  useEffect(() => {
    if(additionalFamDetail?.occupationData){
      setOccupationData(additionalFamDetail?.occupationData?.map(item => ({
        value: item.id,  
        label: item.name,
        disabled:item.heading == "Y" ? true :false
      })))
    }
  }, [additionalFamDetail])

  useEffect(() => {
    if(formik.values.brotherCount || formik.values.marriedBrotherCount || formik.values.brotherNumber || formik.values.sisterCount || formik.values.marriedSisterCount || formik.values.sisterNumber){
      setSiblingsDisabled(true);
    }else{
      setSiblingsDisabled(false);
    }
    
  }, [formik.values.brotherCount,formik.values.marriedBrotherCount,formik.values.brotherNumber,formik.values.sisterCount,formik.values.marriedSisterCount,formik.values.sisterNumber])
  
  
    const handleSkip = () => {
      if(isCommunitySite){
        if(localStorage.getItem("submitpref")){
          localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
          navigate(RouteSegments.MATCH_GROUP)
        }
      }else{
        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.DOWNLOAD_PAGE)
        navigate(RouteSegments.DOWNLOAD_PAGE)
      }
    }
  
  const nameCheckList = async (value,e,field) => {
      let request = {
        name: value
      };
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
        request
      );
        if (data.data.status === "Success") {
          formik.setFieldError(field, data.data.data.Messagge)
        }
  };

  useEffect(() => {

    if(formik?.values?.brotherCount){
      if(formik?.values?.marriedBrotherCount > formik?.values?.brotherCount){
        formik.setFieldValue('marriedBrotherCount', null)
      }else{
        setMarriedBroCountOptions(countOptions.filter(val => val.value <= formik.values.brotherCount))
      }
    }else if(formik?.values?.brotherCount == 0){
      formik.setFieldValue('marriedBrotherCount', null);
    }
    
  }, [formik.values.brotherCount])

  useEffect(() => {

    if(formik?.values?.sisterCount){
      if(formik?.values?.marriedSisterCount > formik?.values?.brotherCount){
        formik.setFieldValue('marriedSisterCount', null)
      }else{
      setMarriedSisterCountOptions(countOptions.filter(val => val.value <= formik.values.sisterCount))
      }
    }else if(formik?.values?.sisterCount == 0){
      formik.setFieldValue('marriedSisterCount', null);
    }
    
  }, [formik.values.sisterCount])
  

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="p-0 md:p-0 md:py-[1rem] lg:px-[2rem] bg-[#FFFFFF]">
            <div className="mx-2">
              <div className="mt-[4rem] md:mt-[2rem] lg:mt-[0rem]">
                <div className="space-y-2">
                  <p className="text-[#D63838] font-bold text-[18px] md:text-[18px] lg:text-[22px]">
                    Tell us more about your family
                  </p>
                </div>
              </div>

              <FamilyEditTextView
                title={"Father's Name"}
                inputType={"text"}
                inputID={"fathersName"}
                inputName={"fathersName"}
                value={formik.values.fathersName}
                onChange={(e)=>{
                    nameCheckList(e.target.value,e,"fathersName");
                    let text = e.target.value ?? "";
                    text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                    text = text.charAt(0).toUpperCase() + text.slice(1)
                    var splitStr = text.toLowerCase().split(' ');
                    for (var i = 0; i < splitStr.length; i++) {
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                    }
                    text =  splitStr.join(' '); 
                    e.target.value = text;
                    if ((e.target.value.split(".").length - 1) <= 1) {
                      formik.handleChange(e)
                    }
                }}
                error={formik.errors.fathersName}
                required={false}
                menu="Occupation"
                placeholder="Enter Father's Name"
                className="placeholder-color"
              />
              <SelectFamilyViewWithHeading
                title={"Father's Occupation"}
                inputID={"fathersOccupation"}
                inputName={"fathersOccupation"}
                value={formik.values.fathersOccupation}
                onChange={(selected, ev) => {
                  formik.setFieldValue("fathersOccupation", selected);
                }}
                error={formik.errors.fathersOccupation}
                options={occupationData}
                required={false}
                menu="Occupation"
              />
              <div className="grid  grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-4  md:mt-[2rem]">
                <div className="col-span-9 md:col-span-3 lg:col-span-3 flex items-center   ">
                  <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F] font-semibold  ">
                  Father's Mobile Number
                  </p>
                </div>
                <div className=" md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
                  <p className="font-bold">:</p>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-5 md:pl-[0.5rem] grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-[6rem] md:gap-[8rem] lg:gap-4 items-center pt-2 md:pt-0 lg:pt-0">
                  <div className="col-span-1">
                    <SelectFamilyNumberInput
                      name="fisdCode"
                      title="Parent's IsdCode"
                      value={formik.values.fisdCode}
                      onChange={(selected, ev) => {
                        formik.setFieldValue("fisdCode", selected);
                      }}
                      error={formik.errors.fisdCode}
                      defaultValue={formik.values.fisdCode}
                      options={[{ value: "", label: "Select", disabled: true },...props?.parentsIsdCodeDropDown]}
                      menu="Numbercode"
                      disabled={showFather}
                      className={showFather == true ? "cursor-not-allowed lg:!w-[6rem] xl:!w-[7rem] 2xl:!w-[9rem]" : "lg:!w-[6rem] xl:!w-[7rem] 2xl:!w-[9rem]"}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-2 lg:col-span-6 lg:col-start-3">
                    <FamilyBasicEditTextView
                      inputID={"fMobileNumber"}
                      inputName={"fMobileNumber"}
                      value={formik.values.fMobileNumber}
                      onChange={async(e) => {
                        let inputValue = e.target.value;
                        if (validateNumber(inputValue)) {
                          await formik.setFieldValue("fMobileNumber",inputValue)
                          if(!showMother){
                            if(inputValue.length == 10 && formik?.values?.fisdCode == "+91"){
                              await formik.setFieldValue("isFather",true);
                            }else if((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.fisdCode != "+91"){
                              await formik.setFieldValue("isFather",true);
                            }
                            else{
                              await formik.setFieldValue("isFather",false);
                            }
                          }
                        }
                      }}
                      error={formik.errors.fMobileNumber}
                      inputType={"tel"}
                      menu="Numbercode"
                      disabled={showFather}
                      className={showFather == true ? "cursor-not-allowed" : "placeholder-color "}
                      placeholder="Enter Father's Mobile Number"
                    />
                  </div>
                </div>
              </div>
              {showMother ? <></> :
              <div className="grid grid-cols-12 md:grid-cols-9 lg:grid-cols-9 lg:mr-0">
                <label className="col-span-12 md:col-start-5 md:col-span-5 lg:col-start-5 lg:col-span-6 relative flex mt-5 md:pl-2 lg:pl-2">
                  <input
                    id="isFather"
                    name="isFather"
                    type="checkbox"
                    checked={formik.values.isFather}
                    value={formik.values.isFather}
                    onChange={async(e)=>{
                      await formik.setFieldValue("isFather",e.target.checked);
                      if(e.target.checked){
                        await formik.setFieldValue("isMother",false);
                      }
                    }}
                    className={`mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181]  ${showFather == true ? "cursor-not-allowed" : ""}`}
                    autoComplete="off"
                    disabled={showFather}
                  />
                  <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                    <p className="hover:text-[#151515] pl-2">
                    Add this number to my profile as Parent's Number
                    </p>
                  </span>
                </label>
              </div>
              }

              <FamilyEditTextView
                title={"Mother's Name"}
                inputType={"text"}
                inputID={"mothersName"}
                inputName={"mothersName"}
                value={formik.values.mothersName}
                onChange={(e)=>{
                  nameCheckList(e.target.value,e,"mothersName");
                  let text = e.target.value ?? "";
                  text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                  text = text.charAt(0).toUpperCase() + text.slice(1)
                  var splitStr = text.toLowerCase().split(' ');
                  for (var i = 0; i < splitStr.length; i++) {
                      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                  }
                  text =  splitStr.join(' '); 
                  e.target.value = text;
                  if ((e.target.value.split(".").length - 1) <= 1) {
                    formik.handleChange(e)
                  }
              }}
                error={formik.errors.mothersName}
                required={false}
                menu="MothersName"
                placeholder="Enter Mother's Name"
                className="placeholder-color"
              />
              <SelectFamilyViewWithHeading
                title={"Mother's Occupation"}
                inputID={"mothersoccupation"}
                inputName={"mothersoccupation"}
                value={formik.values.mothersOccupation}
                onChange={(selected, ev) => {
                  formik.setFieldValue("mothersOccupation", selected);
                }}
                error={formik.errors.mothersOccupation}
                options={occupationData}
                required={false}
                menu="Occupation"
              />

            <div className="grid  grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-4  md:mt-[2rem]">
                <div className="col-span-9 md:col-span-3 lg:col-span-3 flex items-center   ">
                  <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F] font-semibold  ">
                  Mother's Mobile Number
                  </p>
                </div>
                <div className=" md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
                  <p className="font-bold">:</p>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-5 md:pl-[0.5rem] grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-[6rem] md:gap-[8rem] lg:gap-4 items-center pt-2 md:pt-0 lg:pt-0">
                  <div className="col-span-1">
                    <SelectFamilyNumberInput
                      name="misdCode"
                      title="Mother's IsdCode"
                      value={formik.values.misdCode}
                      onChange={(selected, ev) => {
                        formik.setFieldValue("misdCode", selected);
                      }}
                      error={formik.errors.misdCode}
                      defaultValue={formik.values.misdCode}
                      options={[{ value: "", label: "Select", disabled: true} ,...props?.parentsIsdCodeDropDown]}
                      menu="Numbercode"
                      disabled={showMother}
                      className={`{${showMother == true ? "cursor-not-allowed " : ""} lg:!w-[6rem] xl:!w-[7rem] 2xl:!w-[9rem]`}
                      
                    />
                  </div>
                  <div className="col-span-2 md:col-span-2 lg:col-span-6 lg:col-start-3">
                    <FamilyBasicEditTextView
                      inputID={"motherMobileNumber"}
                      inputName={"motherMobileNumber"}
                      value={formik.values.motherMobileNumber}
                      onChange={async(e) => {
                        let inputValue = e.target.value;
                        if (validateNumber(inputValue)) {
                          await formik.setFieldValue("motherMobileNumber",inputValue)
                          if(!showFather){
                            if(inputValue.length == 10 && formik?.values?.misdCode == "+91"){
                              await formik.setFieldValue("isMother",true);
                            }else if((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.misdCode != "+91"){
                              await formik.setFieldValue("isMother",true);
                            }
                            else{
                              await formik.setFieldValue("isMother",false);
                            }
                          }
                        }
                      }}
                      error={formik.errors.motherMobileNumber}
                      inputType={"tel"}
                      menu="Numbercode"
                      disabled={showMother}
                      className={showMother == true ? "cursor-not-allowed placeholder-color" : "placeholder-color "}
                      placeholder="Enter Mother's Mobile Number"
                    />
                  </div>
                </div>
              </div>
              {showFather ? <></> :
              <div className="grid grid-cols-12 md:grid-cols-9 lg:grid-cols-9 lg:mr-0">
                <label className="col-span-12 md:col-start-5 md:col-span-5 lg:col-start-5 lg:col-span-6 relative flex mt-5 md:pl-2 lg:pl-2">
                  <input
                    id="isMother"
                    name="isMother"
                    type="checkbox"
                    // checked={formik.values.isFather == true ? false :formik.values.isMother}
                    checked={formik.values.isFather == true ? '' : formik.values.isMother}
                    value={formik.values.isMother}
                    onChange={async(e)=>{
                      await formik.setFieldValue("isMother",e.target.checked);
                      if(e.target.checked){
                        await formik.setFieldValue("isFather",false);
                      }
                    }}
                    className={`mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181]  ${showMother == true ? "cursor-not-allowed" : ""}`}
                    autoComplete="off"
                    disabled={showMother}
                  />
                  <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                    <p className="hover:text-[#151515] pl-2">
                    Add this number to my profile as Parent's Number
                    </p>
                  </span>
                </label>
              </div>
              }
            </div>
          </div>
        </div>
        <div>
          <div className="p-0 lg:p-[2rem] bg-[#FFFFFF]">
            <div className="mx-2">
              <div className="mt-[2rem] flex justify-between items-center">
                <div className="space-y-2">
                  <p className="text-[#D63838] font-bold text-[18px] md:text-[18px] lg:text-[22px]">Siblings</p>
                </div>
                <div>
                <label className={`relative justify-center flex ${siblingsDisabled ? "cursor-not-allowed" : ""}`}>
                    <input
                      id="haveSiblings"
                      name="haveSiblings"
                      type="checkbox"
                      value={formik.values.haveSiblings}
                      onChange={(e) => 
                        {
                          formik.handleChange(e)
                          setNoSiblings(e.target.checked)
                        }}
                      className={`mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181]  ${siblingsDisabled ? "cursor-not-allowed" : ""}`}
                      autoComplete="off"
                      disabled={siblingsDisabled}
                    />
                    <span className="text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <p className="hover:text-[#151515] pl-2">
                        I have no siblings
                      </p>
                    </span>
                  </label>
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-12 lg:grid-cols-2 items-center gap-4 md:gap-4 lg:gap-0">
                <div className="md:col-span-6 lg:col-span-1">
                  <SelectFamilyInput
                      title="Brothers"
                      menu="aferRegister"
                      name="brothercount"
                      value={formik.values.brotherCount}
                      error={formik.errors.brotherCount}
                      defaultValue={formik.values.familyStatus}
                      disabled={noSiblings}
                      options={[{ value: null, label: "Select", disabled: true },...countOptions]}
                      onChange={(selected) =>{
                        formik.setFieldValue("brotherCount", selected)
                      }}
                  />
                </div>
                
                <div className="md:col-span-6 lg:col-span-1">
                  <SelectFamilyInput
                      title="Married"
                      menu="aferRegister"
                      name="marriedBrotherCount"
                      value={formik.values.marriedBrotherCount}
                      error={formik.errors.marriedBrotherCount}
                      defaultValue={formik.values.marriedBrotherCount}
                      disabled={noSiblings || formik.values.brotherCount == 0}
                      onChange={(selected) => {
                        formik.setFieldValue("marriedBrotherCount", selected);
                      }}
                      options={[{ value: null, label: "Select", disabled: true },...marriedBroCountOptions]}
                    />

                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                <div className="col-span-1 lg:col-span-2 grid grid-cols-9 md:grid-cols-12 lg:grid-cols-12 mt-[2rem] ">
                  <div className="col-span-9 md:col-span-2 lg:col-span-3 flex items-center  ">
                    <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F]  font-semibold  ">
                      Brother's Number
                    </p>
                  </div>
                  <div className="col-span-1 hidden md:flex lg:flex justify-center items-center">
                    <p className="font-bold">:</p>
                  </div>
                  <div className="col-span-9 md:col-span-9 lg:col-span-8 pt-2 md:pt-0 lg:pl-2">
                      <div className="flex space-x-12 md:space-x-8 lg:space-x-3 gap-4 md:gap-0 lg:gap-0">
                        <SelectFamilyNumberInput
                          name="broIsdCode"
                          value={formik.values.broIsdCode}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("broIsdCode", selected);
                          }}
                          error={formik.errors.broIsdCode}
                          defaultValue={formik.values.broIsdCode}
                          options={[{ value: "", label: "Select", disabled: true },...props?.parentsIsdCodeDropDown]}
                          disabled={noSiblings}
                          className={noSiblings ? "cursor-not-allowed" : ""}
                          menu="Numbercode"

                        />

                        <FamilyBasicEditTextView
                          inputID={"brotherNumber"}
                          inputName={"brotherNumber"}
                          value={formik.values.brotherNumber}
                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e);
                            }
                          }}
                          error={formik.errors.brotherNumber}
                          inputType={"tel"}
                          disabled={noSiblings}
                          className={`${noSiblings ? "cursor-not-allowed" : "placeholder-color"}`}
                          placeholder="Enter Brother's Mobile Number"
                        />
                      </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 items-center gap-4 md:gap-4 lg:gap-0">
                <div>
                  <SelectFamilyInput
                    title="Sisters"
                    name="sistercount"
                    menu="aferRegister"
                    value={formik.values.sisterCount}
                    defaultValue={formik.values.sisterCount}
                    error={formik.errors.sisterCount}
                    disabled={noSiblings}
                    options={[{ value: null, label: "Select", disabled: true },...countOptions]}
                    onChange={(selected) =>{
                      formik.setFieldValue("sisterCount", selected)
                    }}
                  />
                </div>
                <div>
                  <SelectFamilyInput
                    title="Married"
                    name="marriedSisterCount"
                    menu="aferRegister"
                    onChange={(selected) => {
                      formik.setFieldValue("marriedSisterCount", selected);
                    }}
                    defaultValue={formik.values.marriedSisterCount}
                    value={formik.values.marriedSisterCount}
                    disabled={noSiblings || formik.values.sisterCount == 0} 
                    options={[{ value: null, label: "Select", disabled: true },...marriedSisterCountOptions]}
                    error={formik.errors.marriedSisterCount}
                  />

                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                <div className="col-span-1 lg:col-span-2 grid grid-cols-9 md:grid-cols-12 lg:grid-cols-12 mt-[2rem] ">
                  <div className="col-span-9 md:col-span-2 lg:col-span-3 flex items-center">
                    <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F]  font-semibold  ">
                      Sister's Number
                    </p>
                  </div>
                  <div className="col-span-1 hidden md:flex lg:flex justify-center items-center">
                    <p className="font-bold">:</p>
                  </div>
                  <div className="col-span-9 md:col-span-9 lg:col-span-8 pt-2 md:pt-0 lg:pl-2">
                    <p className="">
                      <div className="flex space-x-12 md:space-x-8 lg:space-x-3 gap-4 md:gap-0 lg:gap-0">
                        <SelectFamilyNumberInput
                          name="sisIsdCode"
                          title="Sister's IsdCode"
                          value={formik.values.sisIsdCode}
                          onChange={(selected, ev) => {
                            formik.setFieldValue("sisIsdCode", selected);
                          }}
                          error={formik.errors.sisIsdCode}
                          defaultValue={formik.values.sisIsdCode}
                          options={[{ value: "", label: "Select", disabled: true }, ...props?.parentsIsdCodeDropDown]}
                          disabled={noSiblings}
                          className={noSiblings ? "cursor-not-allowed" : "placeholder-color"}
                          menu="Numbercode"

                        />

                        <FamilyBasicEditTextView
                          inputID={"sisterNumber"}
                          inputName={"sisterNumber"}
                          value={formik.values.sisterNumber}
                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e);
                            }
                          }}
                          error={formik.errors.sisterNumber}
                          inputType={"tel"}
                          disabled={noSiblings}
                          className={`${noSiblings ? "cursor-not-allowed" : "placeholder-color"}`}
                          placeholder="Enter Sister's Mobile Number"
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
          
              <SelectFamilyViewWithHeading
                title={"Assets Owned by Family"}
                inputID={"assetInfo"}
                inputName={"assetInfo"}
                value={formik?.values?.assetInfo}
                onChange={(selected, ev) => {
                  formik.setFieldValue("assetInfo", selected);
                }}
                error={formik.errors.assetInfo}
                options={assetOptions}
                required={false}
                disabled={noSiblings}
                defaultValue={null}
                name={"asset"}
                className={noSiblings ? "cursor-not-allowed" : ""}
                menu="aferRegister"
                />
              </div>
          </div>
        </div>
        <div className="flex  mt-7 items-center justify-around  py-4">
                  <div></div>
                  <div className="">
                    <button
                      type="submit"
                      className={`text-[14px]  text-white px-4 lg:px-[2.5rem] py-2.5 rounded-md ${!formik.dirty ? "bg-[#DA3535] opacity-50" : "bg-[#DA3535] cursor-pointer"}`}
                      disabled={!formik.dirty}
                    >
                      {formik.isSubmitting ?<div className="flex"><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>Submitting...</span></div> : "Submit & Continue"}
                    </button>
                  </div>
                  <div className="pl-[6rem] md:pl-[12rem]">
                    {isCommunitySite ? null : (
                      <div className="flex justify-end ">
                        <button
                          onClick={() => {handleSkip();}}
                          type="button"
                          className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline"
                        >
                          Skip
                        </button>
                      </div>
                    )}
                  </div>
        </div>
      </form>
    </>
    
  );
};

export default AboutFamily;
